interface LogoProps {
  className: string;
  style: object;
  onClick?: () => void;
}

export const ApteanLogo: React.FC<LogoProps> = props => {
  const { className, style } = props;
  return (
    <svg
      className={className}
      style={style}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 322.74 318.4"
    >
      <path
        d="M339.56,255.68l-139.79-229a12.33,12.33,0,0,0-14.62-5.23l143.49,235a12.88,12.88,0,0,1,.59,12.32l-28.32,58.47a13.07,13.07,0,0,1-2,3,12.31,12.31,0,0,0,14-6.62l27.21-56.16A12.37,12.37,0,0,0,339.56,255.68Z"
        transform="translate(-18.63 -20.8)"
      />
      <polygon points="196.95 275.73 217.34 269.22 233.44 295.77 196.95 275.73" />
      <polygon points="101.71 84.07 151.34 164.26 138.49 193 37.3 223.88 101.71 84.07" />
      <path
        d="M19.68,323.35,51,255.41,188.16,213.5,231,282.36,32.88,338.76A11.17,11.17,0,0,1,19.68,323.35Z"
        transform="translate(-18.63 -20.8)"
      />
      <path
        d="M317.83,268.84l-27.31,56.37a12.4,12.4,0,0,1-21.68,1.18L124.53,95.77l30.52-67.28a12.41,12.41,0,0,1,21.89-1.34L317.25,257A12.42,12.42,0,0,1,317.83,268.84Z"
        transform="translate(-18.63 -20.8)"
      />
      <path
        d="M317.83,268.84l-27.31,56.37a12.4,12.4,0,0,1-21.68,1.18L124.53,95.77l30.52-67.28a12.41,12.41,0,0,1,21.89-1.34L317.25,257A12.42,12.42,0,0,1,317.83,268.84Z"
        transform="translate(-18.63 -20.8)"
      />
    </svg>
  );
};

export const ApteanArrows: React.FC<LogoProps> = props => {
  const { className, style, onClick } = props;
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g>
        <polygon fill="#f3eb1a" points="15.75,5 11.25,5 15.25,12 19.75,12" />
        <polygon fill="#c8d02d" points="15.25,12 11.25,19 15.75,19 19.75,12" />
      </g>
      <g>
        <polygon fill="#f3eb1a" points="8.25,5 3.75,5 7.75,12 12.25,12" />
        <polygon fill="#c8d02d" points="7.75,12 3.75,19 8.25,19 12.25,12" />
      </g>
    </svg>
  );
};
