import './index.css';
import './util/i18n';

import { createRoot } from 'react-dom/client';
import './SuisseIntlCondBold.otf';
import { CssBaseline } from '@mui/material';
import { DateTime } from 'luxon';
import AppLegacy from './features/app/AppLegacy';
import App from './features/app/App';
import {
  StorageKeyAuthProvider,
  AuthProviderAuth0,
  AuthProviderOIDC,
  IP_IAM_INDICATOR,
  APTEAN_IAM_AUTHORITY,
  APTEAN_IAM_CLIENT_ID,
  APTEAN_IAM_REALM,
  QUERY_PARAM_IP,
  QUERY_PARAM_REDIRECT,
  StorageKeyRedirect,
  StorageKeyAppEntryTime,
  StorageKeyFrontChannelLogoutRequestedTime,
  IAM_EDI_PORTAL_BASE_URL,
  EDI_PORTAL_BASE_URL,
} from './util/Constants';
import StylingProvider from './features/stylingProvider/StylingProvider';
import AppProvider from './components/AppProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import PostLogout from './components/PostLogout';
import { clearSSOSessionStorage } from './util/security';
import FrontChannelLogout from './components/FrontChannelLogout';
import { AuthProvider } from 'react-oidc-context';

console.log('entering commerce app index.tsx');

const queryParams = new URLSearchParams(window.location.search);
let ipIndicator = queryParams.get(QUERY_PARAM_IP);
let redirectIndicator = queryParams.get(QUERY_PARAM_REDIRECT);
let landingPageIndicator = queryParams.get("page");

const redirectToEdiConsumerPortal = () => {
  // If the landing page is commerce, do not redirect
  // This is to allow a way to get to commerce still if absolutely necessary
  if (landingPageIndicator === "commerce") {
    return;
  }
  let href = ipIndicator === IP_IAM_INDICATOR ? IAM_EDI_PORTAL_BASE_URL : EDI_PORTAL_BASE_URL;
  const url = new URL(href);
  queryParams.forEach((value, key) => {
    url.searchParams.append(key, value);
  });
  window.location.assign(url.href);
};

// Force a Redirect to EDI Consumer Portal before this app is loaded
redirectToEdiConsumerPortal();

// Set entry time in session storage if it does not exist
// and the page is not the logout page
let sessionEntryTime = sessionStorage.getItem(StorageKeyAppEntryTime);
if (!sessionEntryTime && window.location.pathname !== '/logout' && window.location.pathname !== '/frontchannellogout') {
  let entryTime = DateTime.now().toUTC().toISO();
  sessionStorage.setItem(StorageKeyAppEntryTime, entryTime);
}

// Check if flag has been set in local storage to logout
window.addEventListener(
  'storage',
  (event: StorageEvent) => {
    if (event.key === StorageKeyFrontChannelLogoutRequestedTime && event.newValue && sessionStorage.getItem(StorageKeyAppEntryTime)) {
      // Do not logout if page was opened after logout request.
      let logoutDateTime = DateTime.fromISO(event.newValue);
      let entryDateTime = DateTime.fromISO(sessionStorage.getItem(StorageKeyAppEntryTime) as string);
      if (logoutDateTime > entryDateTime) {
        clearSSOSessionStorage();
        window.location.href = 'logout';
      }
    }
  },
  false,
);

const container = document.getElementById('root')!;
const root = createRoot(container);

// based on query parameter, set the auth provider in session storage
// for use in the app or use existing value if already set
if (ipIndicator === IP_IAM_INDICATOR) {
  sessionStorage.setItem(StorageKeyAuthProvider, AuthProviderOIDC);
} else if (sessionStorage.getItem(StorageKeyAuthProvider) === AuthProviderOIDC) {
  ipIndicator = IP_IAM_INDICATOR;
} else {
  sessionStorage.setItem(StorageKeyAuthProvider, AuthProviderAuth0);
}

if (redirectIndicator) {
  console.log('came from redirect from: ' + redirectIndicator);
  sessionStorage.setItem(StorageKeyRedirect, redirectIndicator);
}

console.log('ipIndicator: ' + ipIndicator);
console.log(sessionStorage);

const oidcConfig = {
  authority: `${APTEAN_IAM_AUTHORITY}/realms/${APTEAN_IAM_REALM}`,
  client_id: APTEAN_IAM_CLIENT_ID,
  redirect_uri: window.location.origin,
  // Not sure this actually does anything. Need to manually redirect to /logout in logout event handler.
  post_logout_redirect_uri: window.location.origin + '/logout',
  /* According to oidc-react-context: 
   * You must provide an implementation of onSigninCallback to oidcConfig to remove the payload from the URL upon successful login. 
   * Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
  */
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  // Automatically renew the token silently when it expires
  automaticSilentRenew: true,
};

const getAuthProvider = () => {
  if (ipIndicator === IP_IAM_INDICATOR) {
    return (
      <AuthProvider {...oidcConfig}>
        <AppProvider>
          <App />
        </AppProvider>
      </AuthProvider>
    );
  } else {
    return (
      <Auth0Provider
        domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
        clientId={`${process.env.REACT_APP_AUTH_CLIENTID}`}
        audience={`${process.env.REACT_APP_COMMERCE_PORTAL_API_ENDPOINT}/`}
        redirectUri={window.location.origin}
      >
        <AppProvider>
          <AppLegacy />
        </AppProvider>
      </Auth0Provider>
    );
  }
};

const getRender = () => {
  const pathName = window.location.pathname;
  if (pathName === '/logout') {
    return <PostLogout />;
  }
  if (pathName === '/frontchannellogout') {
    return <FrontChannelLogout />;
  }
  return getAuthProvider();
};

root.render(
  <StylingProvider>
    <CssBaseline />
    {getRender()}
  </StylingProvider>,
);
