import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getSSOAccessToken, sec } from '../util/security';
import { StorageKeyAuthProvider, AuthProviderOIDC } from '../util/Constants';

const getAccessToken = async () => {
  console.log('[0] in empty api');
  console.log(sessionStorage);
  
  let token;
  // get the token based on the auth provider
  const authProvider = sessionStorage.getItem(StorageKeyAuthProvider);
  if (authProvider && authProvider === AuthProviderOIDC) {
    token = getSSOAccessToken();
  } else {
    token = await sec.getAccessTokenSilently()();
  }
  return token;
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_COMMERCE_PORTAL_API_ENDPOINT,
  prepareHeaders: async (headers) => {
    const token = await getAccessToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    console.log(headers);
    return headers;
  },
});
  
// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
  