import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { ApteanArrows, ApteanLogo } from './ApteanLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '0px',
      boxShadow: 'none',
      height: '290px',
      overflow: 'visible',
      '&:hover': {
        outline: '4px solid',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: 'auto',
        maxHeight: '240px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        margin: 'auto',
        maxHeight: '260px',
      },
    },
    productCardFocusVisible: {
      '&:focus': {
        outline: '4px solid #fffc3b',
      },
    },
    productCardFocusWithExceptions: {
      '&:focus': {
        outline: 'none',
        boxShadow: '-4px 0px 0 #fffc3b, -4px -4px 0 #fffc3b, 4px -4px 0 #fffc3b, 4px 0px 0 #fffc3b',
      },
    },
    productCardGrid: {
      width: '100%',
      height: '100%',
    },
    productLogo: {
      height: '72px',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        height: '42px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        height: '48px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        height: '60px',
      },
    },
    productTitle: {
      fontSize: '72px',
      fontFamily: 'Suisse Intl Cond Bold',
      lineHeight: '72px',
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        fontSize: '42px',
        lineHeight: '42px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '48px',
        lineHeight: '48px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '60px',
        lineHeight: '60px',
      },
    },
    productTitleBox: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    productTitleBoxAccess: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1),
      },
    },
    productSubTitleBox: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    productSubTitleBoxNoAccess: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      margin: 0,
      padding: 0,
    },
    productSubTitle: {
      color: '#fffc3a',
      fontFamily: 'Suisse Intl Cond Bold',
      fontSize: '37px',
      lineHeight: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: '24.5px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '28px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '32px',
      },
    },
    productSubTitleNoAccess: {
      color: '#655e58',
      fontFamily: 'Suisse Intl Cond Bold',
      fontSize: '28px',
      lineHeight: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: '18.66px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '21px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '24.5px',
      },
    },
    productSubSubTitle: {
      color: 'white',
      fontSize: '24.5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        fontSize: '21px',
      },
    },
    productSubSubTitleNoAccess: {
      color: '#655e58',
      fontSize: '21px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        fontSize: '18px',
      },
    },
    noAccessText: {
      fontSize: '28px',
      color: '#655e58',
      width: '100%',
      textAlign: 'center',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        fontSize: '18px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '21px',
      },
    },
    noAccessTextWithSubtitle: {
      fontSize: '24.5px',
      color: '#655e58',
      width: '100%',
      textAlign: 'center',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '21px',
      },
    },
    moreInfoButton: {
      backgroundColor: '#3A3A3A',
      color: 'white',
      borderRadius: '0px',
      fontSize: '18px',
      padding: theme.spacing(0.75, 3),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
      },
      [theme.breakpoints.between('sm', 'md')]: {
        marginBottom: theme.spacing(1),
      },
      '&:hover': {
        backgroundColor: '#fffc3b',
        color: '#3A3A3A',
      },
      '&:focus': {
        outline: '3px solid #fffc3b',
      },
    },
    exceptionsLink: {
      textDecoration: 'none',
      width: '100%',
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '-4px 0px 0 #fffc3b, -4px 4px 0 #fffc3b, 4px 4px 0 #fffc3b, 4px 0px 0 #fffc3b',
      },
    },
    exceptionsText: {
      fontSize: '22px',
      fontWeight: 'bold',
      color: 'white',
      margin: 'auto',
      paddingBottom: theme.spacing(0.75),
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    exceptionsBadge: {
      padding: '2px 6px 0px',
      marginRight: '8px',
      height: '25px',
      borderRadius: '12px',
      fontSize: '18px',
      color: 'white',
      backgroundColor: '#E00B00',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
    exceptionsHover: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    apteanArrows: {
      height: '66px',
      position: 'absolute',
      zIndex: 1,
      right: 10,
      bottom: 10,
      [theme.breakpoints.down('lg')]: {
        height: '60px',
      },
      [theme.breakpoints.down('sm')]: {
        right: 8,
        bottom: 8,
      },
    },
  }),
);

interface ProductTileProps {
  onRedirectClick: () => void;
  onExceptionsClick: () => void;
  productName: string;
  userHasAccess: {
    hasAccess: boolean;
    showSubtitle: boolean;
  };
  exceptions: number;
  moreInfoLink: string;
  subTitle?: {
    emphasized: string;
    regular: string | string[];
  };
}

const ProductTile: React.FC<ProductTileProps> = props => {
  const { onRedirectClick, onExceptionsClick, productName, userHasAccess, exceptions, moreInfoLink, subTitle } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t: translate } = useTranslation();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('md'));

  const createTileTitle = () => {
    return (
      <Container className={userHasAccess.hasAccess ? classes.productTitleBoxAccess : classes.productTitleBox}>
        <ApteanLogo
          className={classes.productLogo}
          style={
            userHasAccess.hasAccess
              ? {
                  fill: theme.palette.productCardApteanLogoAccess.main,
                }
              : {
                  fill: theme.palette.productCardNoAccessText.main,
                }
          }
        />
        <Typography
          variant="h6"
          className={classes.productTitle}
          style={
            userHasAccess.hasAccess
              ? {
                  color: theme.palette.productCardAccessText.main,
                }
              : {
                  color: theme.palette.productCardNoAccessText.main,
                }
          }
        >
          {productName}
        </Typography>
      </Container>
    );
  };
  const createTileSubtitle = () => {
    if (subTitle === undefined) {
      return <Container></Container>;
    } else {
      return (
        <Container className={userHasAccess.hasAccess ? classes.productSubTitleBox : classes.productSubTitleBoxNoAccess}>
          <Typography className={userHasAccess.hasAccess ? classes.productSubTitle : classes.productSubTitleNoAccess}>
            {subTitle.emphasized}
          </Typography>
          <Typography className={userHasAccess.hasAccess ? classes.productSubSubTitle : classes.productSubSubTitleNoAccess}>
            {subTitle.regular}
          </Typography>
        </Container>
      );
    }
  };

  const createTileContent = () => {
    if (!userHasAccess.hasAccess) {
      return (
        <CardContent style={{ padding: '0px', height: '100%' }}>
          <Grid
            className={classes.productCardGrid}
            container
            rowSpacing={{
              xs: 0,
              md: 1,
            }}
            justifyContent={{
              xs: 'space-evenly',
              md: 'center',
            }}
            direction="column"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item style={{ width: '100% ' }}>
              {createTileTitle()}
            </Grid>
            {userHasAccess.showSubtitle && (
              <Grid item style={userHasAccess.showSubtitle ? { paddingTop: 0 } : {}}>
                {createTileSubtitle()}
              </Grid>
            )}
            <Grid item style={userHasAccess.showSubtitle ? { paddingTop: 0 } : {}}>
              <Typography className={userHasAccess.showSubtitle ? classes.noAccessTextWithSubtitle : classes.noAccessText}>
                {translate('productTile.noAccess')}
              </Typography>
            </Grid>
            <Grid item style={userHasAccess.showSubtitle ? { paddingTop: 0 } : {}}>
              <Button
                variant="contained"
                href={moreInfoLink}
                target="_blank"
                disabled={moreInfoLink === ''}
                disableElevation={true}
                className={classes.moreInfoButton}
                style={userHasAccess.showSubtitle ? { marginTop: 0 } : {}}
              >
                {translate('productTile.moreInfo')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardActionArea
          className={exceptions > 0 ? classes.productCardFocusWithExceptions : classes.productCardFocusVisible}
          style={
            exceptions > 0
              ? {
                  height: 'auto',
                  flexGrow: 1,
                }
              : {
                  height: '100%',
                }
          }
          onClick={onRedirectClick}
        >
          <Grid
            className={classes.productCardGrid}
            container
            rowSpacing={{
              xs: 0,
              md: 1,
            }}
            justifyContent={{
              xs: 'space-evenly',
              md: 'flex-start',
            }}
            direction="column"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item style={{ width: '100% ' }}>
              {createTileTitle()}
            </Grid>
            {!!subTitle && (
              <Grid item container style={exceptions === 0 && (isMediumDevice || isSmallDevice) ? { marginBottom: '43px' } : {}}>
                {createTileSubtitle()}
              </Grid>
            )}
          </Grid>
        </CardActionArea>
      );
    }
  };

  const createTileExceptions = () => {
    return (
      <Link component="button" onClick={onExceptionsClick} className={classes.exceptionsLink}>
        <Typography className={classes.exceptionsText}>
          <span className={classes.exceptionsBadge}>{exceptions > 99 ? '99+' : exceptions}</span>
          <span className={classes.exceptionsHover}>
            {exceptions > 1 ? translate('productTile.exceptions') : translate('productTile.singleException')}
          </span>
        </Typography>
      </Link>
    );
  };

  return (
    <Card
      className={classes.productCard}
      style={
        userHasAccess.hasAccess
          ? { backgroundColor: theme.palette.productCardAccessBackground.main, outlineColor: '#fffc3b' }
          : { backgroundColor: theme.palette.productCardNoAccessBackground.main, outlineColor: 'transparent' }
      }
    >
      {createTileContent()}
      {exceptions > 0 && userHasAccess.hasAccess && <CardActions style={{ padding: 0 }}>{createTileExceptions()}</CardActions>}
      {userHasAccess.hasAccess && (
        <span style={{ position: 'relative' }}>
          <ApteanArrows className={classes.apteanArrows} style={{ cursor: 'pointer' }} onClick={onRedirectClick} />
        </span>
      )}
    </Card>
  );
};

export default ProductTile;
