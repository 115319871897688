import { User } from '../api/userInfo.api';
import SettingsMenu from './SettingsMenu';
import { clearSSOSessionStorage } from '../util/security';
import { useTranslation } from 'react-i18next';

interface SettingsMenuSSOProps {
  afterClick: () => void;
  viewerUser?: User | undefined;
  isAdmin: boolean;
}

const SettingsMenuApteanSSO: React.FC<SettingsMenuSSOProps> = props => {
  const { afterClick, viewerUser, isAdmin } = props;
  const { t: translate } = useTranslation();
  
  const handleLogout = () => {
    // clear the session storage as part of the logout process
    clearSSOSessionStorage();
    window.location.href = window.location.origin + '/logout';
    afterClick();
  };

  return (
    <SettingsMenu 
      afterClick={afterClick}
      handleLogout={handleLogout} 
      viewerUser={viewerUser} 
      isAdmin={isAdmin} 
      logoutButtonText={translate('settingsMenu.exit')}
    />
  );
};

export default SettingsMenuApteanSSO;
