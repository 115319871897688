import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setupStore } from '../app/store';

interface AppProviderProps {
    children?: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = (props) => {
    
    console.log(`[1] AppProvider calling setupStore`);
    const store = setupStore();

    return (
        <Provider store={store}>
            <BrowserRouter>
                {props.children}
            </BrowserRouter>
        </Provider>
    );
};

export default AppProvider;
