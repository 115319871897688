import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { UserForCustomer } from '../../api/users.api';

import { Role } from '../../util/Constants';
import { getDomainServiceRole } from '../../util/Roles';
import { isEmailValid } from '../../util/Validation';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../api/userInfo.api';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import { TableTemplate } from '../TableTemplate';
import { makeDataRowsFromSubscriptions, SubscriptionInfoRow } from '../../features/userManagement/UserManagementApi';

const roleOptions = [
  {
    value: Role.Reader,
    label: 'Reader',
  },
  {
    value: Role.Admin,
    label: 'Admin',
  },
];

interface AddUserDialogProps {
  isOpen: boolean;
  onSave: (firstName: string, lastName: string, email: string, commerceRole: Role, ediRole: Role, subscriptions: Subscription[]) => void;
  onClose: () => void;
  userForEdit?: UserForCustomer;
  apiStatus: QueryStatus;
  errorMessage?: string;
  emails: string[];
  availableSubscriptions: Subscription[];
}

const AddUserDialog: React.FC<AddUserDialogProps> = props => {
  const { isOpen, onSave, onClose, userForEdit, apiStatus, errorMessage, emails, availableSubscriptions } = props;
  const { t: translate } = useTranslation();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [commerceRole, setCommerceRole] = useState<Role>(Role.Reader);
  const [ediRole, setEdiRole] = useState<Role>(Role.Reader);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');  
  const [dataRows, setDataRows] = useState<SubscriptionInfoRow[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const setForUser = useCallback(() => {
    if (userForEdit) {
      setFirstName(userForEdit.firstName || '');
      setLastName(userForEdit.lastName || '');
      setEmail(userForEdit.email || '');
      setCommerceRole((userForEdit.role as Role) || Role.Reader);
      setEdiRole(getDomainServiceRole(userForEdit, 'edi') || Role.Reader);
      if (userForEdit.subscriptions) {
        setSelectedRowIds(findUserSubIds(userForEdit.subscriptions));
      } else {
        // if there is only a single subscription, assign it (it will be shown without interaction)
        if (availableSubscriptions && availableSubscriptions.length === 1){
          setSelectedRowIds(findUserSubIds(availableSubscriptions));
        }
      }
    }
  },[userForEdit, availableSubscriptions]);

  const setToDefaults = useCallback((closing?: boolean) => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setCommerceRole(Role.Reader);
    setEdiRole(Role.Reader);
    if (closing) {
      setSelectedRowIds([]);
      setIsFormDirty(false);
    } else {
      if (availableSubscriptions && availableSubscriptions.length === 1){
        setSelectedRowIds(findUserSubIds(availableSubscriptions));
      } else {
        setSelectedRowIds([]);
      }
    }
  },[availableSubscriptions]);

  useEffect(() => {
    if (isOpen) {
      if (!userForEdit) {
        setToDefaults();
      } else {
        setForUser();
      }
    }
  }, [userForEdit, apiStatus, isOpen, setForUser, setToDefaults]);

  useEffect(() => {
    if (availableSubscriptions && availableSubscriptions.length){
      setDataRows(makeDataRowsFromSubscriptions(availableSubscriptions));
    }
  }, [availableSubscriptions])

  const findUserSubIds = (subs?: Subscription[]) => {
    if (subs && subs.length) {
      let ret = [] as string[];
      subs.forEach(sub => {
        ret.push(sub.tenantId as string);
      });
      return ret;
    }
    else {
      return [];
    }
  }
  const cancelAdd = () => {    
    setToDefaults(true);
    onClose();
  };

  const validateForm = () => {
    if (!firstName.trim()) {
      return false;
    }
    if (!lastName.trim()) {
      return false;
    }
    if (!email || emailInvalid) {
      return false;
    }
    if (selectedRowIds.length < 1) {
      return false;
    }
    return isFormDirty;
  };

  const getSelectedSubscriptions = () => {
    let ret = [] as Subscription[];
    selectedRowIds.forEach(selection => {
      let found = availableSubscriptions.find(sub => sub.tenantId === selection);
      if (found) {
        ret.push(found);
      }
    });
    return ret; 
  };

  const updateSubscriptions = (selectedSubscriptions: Subscription[], ediRole: string) => {
    let subsToUpdate = selectedSubscriptions.filter(s => s.domainService === "edi");
    if (subsToUpdate && subsToUpdate.length > 0) {
      // if ediRole changed, set each selected subscription's role to the new role
      // by mapping to a new subscription array object because we can't directly
      // manipulate the array object that is on the customer originally coming from the data select
      
      const updatedSubs = subsToUpdate.map((sub: Subscription) => {
        return ( 
          {
            domainService: sub.domainService,
            externalTenantId: sub.externalTenantId,
            tenantId: sub.tenantId,
            tenantName: sub.tenantName,
            roles: [ediRole]
          } as Subscription
        ) 
      });
      return updatedSubs;
    }
    return selectedSubscriptions;
  };

  const submitForm = () => {
    if (validateForm()) {
      let subs = getSelectedSubscriptions();
      let updatedSubs = updateSubscriptions(subs, ediRole);
      
      // let the user type what they want, but we need to store the email lowercase, change that before sending
      onSave(firstName, lastName, email.toLowerCase(), commerceRole, ediRole, updatedSubs);
    }
  };

  const validateAndSetEmail = (toValidate: string) => {
    if (toValidate || toValidate === '') {
      const isValid = isEmailValid(toValidate);
      setEmailInvalid(!isValid || emails.includes(toValidate));
      if (!isValid) {
        setEmailErrorText(translate('userManagement.emailInvalid') as string);
      } else if (emails.includes(toValidate)) {
        setEmailErrorText(translate('userManagement.emailDuplicate') as string);
      } else {
        setEmailErrorText('');
      }
      setEmail(toValidate);
    }
  };

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    setLastName(event.target.value);
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    validateAndSetEmail(event.target.value);
  };

  const handleCommerceRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    // Set as the Role value structure.
    const roleChangeValue = event.target.value || Role.Reader;
    setCommerceRole(roleChangeValue as Role);
  };

  const handleEdiRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    // Set as the Role value structure.
    const roleChangeValue = event.target.value || Role.Reader;
    setEdiRole(roleChangeValue as Role);
  };

  const onGridSelectionChanged = (e: any) => {
    setIsFormDirty(true);
    if (e && e.hasOwnProperty('selectedRowKeys')) {
      setSelectedRowIds(e.selectedRowKeys);
    }
  };

  const gridCols = [
    {
      dataField: 'tenantName',
      caption: translate('userManagement.tenant'),
      sortOrder: 'asc',
    },
    {
      dataField: 'tenantId',
      caption: translate('userManagement.tenantId'),
    },
  ] as IColumnProps[];

  const gridOptions: IDataGridOptions = {
    dataSource: dataRows,
    selectedRowKeys: selectedRowIds,
    height: '20vh',
    rowAlternationEnabled: true,
    scrolling: {
      mode: 'virtual',
      preloadEnabled: true,
    },
    sorting: {
      mode: 'multiple',
    },
    selection: {
      mode: 'multiple',
      showCheckBoxesMode: 'always',
      allowSelectAll: false,
    },
    onSelectionChanged: onGridSelectionChanged,
  };

  const getGridColumns = () => {
    return gridCols.map((col, index) => <Column key={index} {...col} />);
  };

  const getSubscriptionContent = () => {
    if (availableSubscriptions && availableSubscriptions.length > 1) {
      return <TableTemplate gridOptions={gridOptions}>{getGridColumns()}</TableTemplate>;
    }
    if (availableSubscriptions && availableSubscriptions.length === 1) {
      return (
        <Grid item>
          <Typography>{`${availableSubscriptions[0].tenantName} ${availableSubscriptions[0].tenantId}`}</Typography>
        </Grid>
      );
    }
    return (
      <Grid item>
        <Typography>{translate("userManagement.noSubscriptions")}</Typography>
      </Grid>
    );
  };

  const firstNameProps = {
    'aria-label': 'first name',
    maxLength: 100,
  };
  const lastNameProps = {
    'aria-label': 'last name',
    maxLength: 100,
  };
  const emailProps = {
    'aria-label': 'email',
    maxLength: 100,
  };

  return (
    <Dialog aria-label="add user dialog" maxWidth="md" open={isOpen} scroll="paper" fullWidth>
      <DialogTitle className="dialogTitle" id="add-user-dialog-title">
        <Typography variant="body1">{userForEdit ? translate("userManagement.editUser") : translate("userManagement.addUser")}</Typography>
      </DialogTitle>
      {errorMessage && (
        <Alert severity="error">
          <Typography>{errorMessage}</Typography>
        </Alert>
      )}
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-first-name"
              fullWidth
              disabled={apiStatus === QueryStatus.pending}
              autoFocus
              value={firstName}
              label={translate('userManagement.firstName')}
              inputProps={firstNameProps}
              onChange={handleFirstNameChange}
              autoComplete="off"
              required
              data-cy="dialog-user-first-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-second-name"
              fullWidth
              disabled={apiStatus === QueryStatus.pending}
              value={lastName}
              label={translate('userManagement.lastName')}
              inputProps={lastNameProps}
              onChange={handleLastNameChange}
              autoComplete="off"
              required
              data-cy="dialog-user-last-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-email"
              fullWidth
              error={emailInvalid}
              helperText={emailErrorText}
              value={email}
              label={translate('userManagement.email')}
              inputProps={emailProps}
              onChange={handleEmailChange}
              autoComplete="off"
              required
              data-cy="dialog-user-email"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-commerce-role"
              fullWidth
              onChange={handleCommerceRoleChange}
              defaultValue={Role.Reader}
              disabled={commerceRole === Role.Primary || apiStatus === QueryStatus.pending}
              inputProps={{ 'aria-label': 'commerce role' }}
              label={translate('userManagement.portRole')}
              value={commerceRole}
              data-cy="dialog-user-role-commerce"
              variant="standard"
              select
            >
              {roleOptions.map(roleValue => (
                <MenuItem key={roleValue.value} value={roleValue.value}>
                  {roleValue.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-edi-role"
              fullWidth
              onChange={handleEdiRoleChange}
              defaultValue={Role.Reader}
              disabled={ediRole === Role.Primary || apiStatus === QueryStatus.pending}
              inputProps={{ 'aria-label': 'edi role' }}
              label={translate('userManagement.ediRole')}
              value={ediRole}
              data-cy="dialog-user-role-edi"
              variant="standard"
              select
            >
              {roleOptions.map(roleValue => (
                <MenuItem key={roleValue.value} value={roleValue.value}>
                  {roleValue.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{translate('userManagement.availableSubscriptions')}</Typography>
            <Grid container justifyContent="center">
              {getSubscriptionContent()}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {apiStatus === QueryStatus.pending && <CircularProgress size={20} />}
        <Button onClick={cancelAdd} disabled={apiStatus === QueryStatus.pending} data-cy="dialog-add-edit-entity-cancel">
          {translate('userManagement.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={apiStatus === QueryStatus.pending || !validateForm()}
          data-cy="dialog-add-edit-entity-save"
        >
          {translate('userManagement.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
