import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Breadcrumbs, Button, Container, Grid, Link, Stack, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Subscription, User } from '../../api/userInfo.api';
import {
  useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation,
  useGetV1CustomersByCustomerIdUsersQuery,
  usePostV1CustomersByCustomerIdUsersMutation,
  usePutV1CustomersByCustomerIdUsersAndUserIdMutation,
  UserForCustomer,
  UserRole,
} from '../../api/users.api';
import { useAppSelector } from '../../app/hooks';
import AddUserDialog from '../../components/dialogs/AddUserDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import { TableTemplate } from '../../components/TableTemplate';
import { Role } from '../../util/Constants';
import { getDomainServiceRole, mapRoleToString } from '../../util/Roles';
import { selectCustomer, selectCustomerId, selectIsAdmin } from '../app/AppSlice';
import { makeDataRowsFromUsers } from './UserManagementApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(4, 0),
    },
    userManagementButton: {
      paddingLeft: '0px',
      paddingRight: theme.spacing(2),
    },
  }),
);

interface UserManagementProps {
  viewerUser?: User;
}

const UserManagement: React.FC<UserManagementProps> = props => {
  const { viewerUser } = props;
  const classes = useStyles();
  const navigate = useNavigate();  
  const { t: translate } = useTranslation();
  const customerId = useAppSelector(selectCustomerId);
  const customer = useAppSelector(selectCustomer);
  const isAdmin = useAppSelector(selectIsAdmin);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [addOpen, setAddOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | undefined>();
  const [addUserErrorMessage, setAddUserErrorMessage] = useState<string | undefined>();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [numSelectedUsers, setNumSelectedUsers] = useState(0);
  const [userForEdit, setUserForEdit] = useState<UserForCustomer | undefined>();
  const [apiStatus, setApiStatus] = useState<QueryStatus>(QueryStatus.uninitialized);
  const [emails, setEmails] = useState<string[]>([]);
  const [customerSubs, setCustomerSubs] = useState<Subscription[]>([]);
  // Because the delete api call returns a 204 on succesful delete, the network response does not provide a successful payload on delete. This state will be used to accurately close the dialog on succesful api call.
  const [deleteDialogToClose, setDeleteDialogToClose] = useState(false);

  const { data: users } = useGetV1CustomersByCustomerIdUsersQuery({ customerId: customerId || '' }, { skip: !customerId });
  const [createUser, createResult] = usePostV1CustomersByCustomerIdUsersMutation();
  const [deleteUser, deleteResult] = useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation();
  const [updateUser, updateResult] = usePutV1CustomersByCustomerIdUsersAndUserIdMutation();

  useEffect(() => {
    if (users) {
      setDataRows(makeDataRowsFromUsers(users));
      const emails = users.reduce<string[]>((result, user) => {
        if (user.email) {
          result.push(user.email);
        }
        return result;
      }, []);
      setEmails(emails);
    }
  }, [users]);

  useEffect(() => {
    if (customer && !isAdmin) {
      navigate('/');
    }
  }, [customer, isAdmin]);

  useEffect(() => {
    if (customer) {      
      // find all entries with edi
      const ediSubscriptions = customer.subscriptions?.filter(sub => sub.domainService === 'edi');
      // add them to setCustomerSubs
      if (ediSubscriptions && ediSubscriptions.length) {
        setCustomerSubs(ediSubscriptions);
      }
    }
  }, [customer]);

  useEffect(() => {
    // If we have one user selected, use that user for updating/deleting
    if (selectedRowIds.length === 1) {
      const id = selectedRowIds[0];
      const user = users?.find(user => user.id === id);
      setUserForEdit(user);
    } else {
      setUserForEdit(undefined);
    }
  }, [selectedRowIds, users]);

  useEffect(() => {
    setApiStatus(createResult.status);
    if (createResult.status === QueryStatus.rejected) {
      setAddUserErrorMessage('There was a problem creating the user. Please try again');
    } else {
      setAddUserErrorMessage(undefined);
    }
  }, [createResult.status]);

  useEffect(() => {
    if (deleteResult.status === QueryStatus.rejected) {
      setDeleteErrorMessage('There was a problem deleting the user. Please try again.');
    } else {
      setDeleteErrorMessage(undefined);
      // Close the dialog if the delete was successful
      if (deleteResult.status === QueryStatus.fulfilled && openDelete && deleteDialogToClose) {
        setOpenDelete(false);
        setDeleteDialogToClose(false);
      }
    }
  }, [deleteResult.status, openDelete, deleteDialogToClose]);

  useEffect(() => {
    setApiStatus(updateResult.status);
    if (updateResult.status === QueryStatus.rejected) {
      setAddUserErrorMessage('There was a problem updating the user. Please try again');
    } else {
      setAddUserErrorMessage(undefined);
    }
  }, [updateResult.status]);

  const onAddUserClick = () => {
    setAddOpen(true);
  };

  const onRemoveUsersClick = () => {
    if (numSelectedUsers) {
      setOpenDelete(true);
    }
  };

  const onDeleteUsersConfirm = async () => {
    if (viewerUser && userForEdit && userForEdit.id && customerId) {
      if (userForEdit.id === viewerUser.id) {
        setDeleteErrorMessage('User can not remove themself.  Please unselect User from list and try again');
      } else {
        // Flag the delete user dialog to be closed on fulfilled api call
        setDeleteDialogToClose(true);
        await deleteUser({ customerId: customerId, userId: userForEdit.id });
      }
    }
  };

  const onDeleteDialogClose = () => {
    setDeleteErrorMessage(undefined);
    setOpenDelete(false);
  };

  const onAddSave = async (firstName: string, lastName: string, email: string, commerceRole: string, ediRole: string, subscriptions: Subscription[]) => {
    if (!customerId) {
      setAddUserErrorMessage('You currently do not have authorization to add or edit a user.');
      return;
    }
    const viewerUserSubscriptions = customer?.subscriptions;
    if (!viewerUserSubscriptions) {
      setAddUserErrorMessage('Your account currently has no subscriptions and cannot add or edit a user.');
      return;
    }
    if (userForEdit && userForEdit.id) {
      // Grab the response from the api call
      const payload = await updateUser({
        customerId: customerId,
        userId: userForEdit.id,
        updateUser: { firstName, lastName, email, role: commerceRole as UserRole, subscriptions },
      }).unwrap();
      if (payload) {
        // If the api call is successful, close the dialog
        setAddOpen(false);
      }
    } else {
      const payload = await createUser({
        customerId: customerId,
        createUser: { firstName, lastName, email, role: commerceRole as UserRole, subscriptions },
      }).unwrap();
      if (payload) {
        setAddOpen(false);
      }
    }
  };

  const onAddClose = () => {
    setAddUserErrorMessage(undefined);
    setAddOpen(false);
  };

  const onGridSelectionChanged = (e: any) => {
    if (e && e.hasOwnProperty('selectedRowKeys')) {
      setSelectedRowIds(e.selectedRowKeys);
      setNumSelectedUsers(e.selectedRowKeys.length);
    }
  };

  const renderCommerceRole = (rowData: { commerceRole: any }) => mapRoleToString(rowData.commerceRole);
  const renderEdiRole = (rowData: { ediRole: any }) => mapRoleToString(rowData.ediRole);

  const gridCols = [
    {
      dataField: 'name',
      caption: translate('userManagement.name'),
      sortOrder: 'asc',
    },
    {
      dataField: 'email',
      caption: translate('userManagement.email'),
    },
    {
      dataField: 'commerceRole',
      caption: translate('userManagement.portRole'),
      calculateCellValue: renderCommerceRole,
    },
    {
      dataField: 'ediRole',
      caption: translate('userManagement.ediRole'),
      calculateCellValue: renderEdiRole,
    },
  ] as IColumnProps[];

  const gridOptions: IDataGridOptions = {
    dataSource: dataRows,
    height: '60vh',
    rowAlternationEnabled: true,
    scrolling: {
      mode: 'virtual',
      preloadEnabled: true,
    },
    sorting: {
      mode: 'multiple',
    },
    selection: {
      mode: 'multiple',
      showCheckBoxesMode: 'always',
      allowSelectAll: false,
    },
    onSelectionChanged: onGridSelectionChanged,
  };

  const getGridColumns = () => {
    return gridCols.map((col, index) => <Column key={index} {...col} />);
  };

  return (
    <Container maxWidth={false} className={classes.rootContainer} disableGutters>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          {translate('userManagement.home')}
        </Link>
        <Typography color="text.primary">{translate('userManagement.home')}</Typography>
      </Breadcrumbs>
      <Stack spacing={2}>
        <Typography component="h1" variant="h6" fontSize={28}>
          {translate('userManagement.home')}
        </Typography>
        <Grid container justifyContent="flex-start">
          <Grid item className={classes.userManagementButton}>
            <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={onAddUserClick}>
              {userForEdit ? translate('userManagement.editUser') : translate('userManagement.addUser')}
            </Button>
          </Grid>
          <Grid item className={classes.userManagementButton}>
            <Button
              variant="outlined"
              startIcon={<PersonRemoveIcon />}
              onClick={onRemoveUsersClick}
              disabled={
                numSelectedUsers !== 1 ||
                userForEdit?.id === viewerUser?.id ||
                userForEdit?.role === Role.Primary ||
                getDomainServiceRole(userForEdit, 'commercePortal') === Role.Primary
              } /** Disable delete button if more than one user is selected, is the viewer user, or has a primary role. */
            >
              {translate('userManagement.deleteUser')}
            </Button>
          </Grid>
        </Grid>
        <TableTemplate gridOptions={gridOptions}>{getGridColumns()}</TableTemplate>
      </Stack>
      <AddUserDialog
        isOpen={addOpen}
        onSave={onAddSave}
        onClose={onAddClose}
        userForEdit={userForEdit}
        apiStatus={apiStatus}
        errorMessage={addUserErrorMessage}
        emails={emails}
        availableSubscriptions={customerSubs}
      />
      <DeleteDialog
        isOpen={openDelete}
        heading={translate('userManagement.delete')}
        message={`${translate('userManagement.confirmation')} ${userForEdit?.firstName} ${userForEdit?.lastName}`}
        onConfirm={onDeleteUsersConfirm}
        onReject={onDeleteDialogClose}
        errorMessage={deleteErrorMessage}
        deleteStatus={deleteResult.status}
      />
    </Container>
  );
};

export default UserManagement;
