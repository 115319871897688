import { User } from '../api/userInfo.api';
import { useAuth0 } from '@auth0/auth0-react';
import SettingsMenu from './SettingsMenu';

interface SettingsMenuAuth0Props {
  afterClick: () => void;
  viewerUser?: User | undefined;
  isAdmin: boolean;
}

const SettingsMenuAuth0: React.FC<SettingsMenuAuth0Props> = props => {
  const { afterClick, viewerUser, isAdmin } = props;
  const { logout } = useAuth0();
  
  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    
    afterClick();
  };

  return (
    <SettingsMenu 
      afterClick={afterClick}
      handleLogout={handleLogout} 
      viewerUser={viewerUser} 
      isAdmin={isAdmin} 
    />
  );
};

export default SettingsMenuAuth0;
