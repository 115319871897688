import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useTranslation } from 'react-i18next';

interface LanguageSelectProps {
    languageChanged: (lang: string) => void;
    menuItemClassName: string;
    language: string;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = props => {
    const { languageChanged, menuItemClassName, language } = props;
    const { t: translate } = useTranslation();
    const [ selectedLang, setSelectedLang ] = useState(language);

    const handleLanguageChange = (event: SelectChangeEvent) => {
        if (event && event.target) {
            setSelectedLang(event.target.value);
            languageChanged(event.target.value);
        }
    };

    useEffect(() => {
        if (language) {          
            setSelectedLang(language);
        }
      }, [language]);

    return (
        <FormControl fullWidth>
            <InputLabel id="settings-menu-language-label">
              {translate('settingsMenu.language')}
            </InputLabel>
            <Select
              labelId="settings-menu-language-label"
              id="settings-menu-language"
              value={selectedLang ?? "not set?"}
              onChange={handleLanguageChange}
              label={translate('settingsMenu.language')}
            >
              <MenuItem className={menuItemClassName} value={'de-DE'}>Deutsch</MenuItem>
              <MenuItem className={menuItemClassName} value={'en-AU'}>English AU</MenuItem>
              <MenuItem className={menuItemClassName} value={'en-CA'}>English CA</MenuItem>
              <MenuItem className={menuItemClassName} value={'en-GB'}>English UK</MenuItem>
              <MenuItem className={menuItemClassName} value={'en-US'}>English US</MenuItem>
              <MenuItem className={menuItemClassName} value={'es-ES'}>Español</MenuItem>
              <MenuItem className={menuItemClassName} value={'fr-FR'}>Français</MenuItem>
              <MenuItem className={menuItemClassName} value={'nl-NL'}>Nederlands</MenuItem>
            </Select>
          </FormControl>
    )
}

export default LanguageSelect;