import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['CustomerUsers'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1CustomersByCustomerIdUsersAndUserId: build.query<
        GetV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        GetV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}` }),
        providesTags: ['CustomerUsers'],
      }),
      deleteV1CustomersByCustomerIdUsersAndUserId: build.mutation<
        DeleteV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        DeleteV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}`, method: 'DELETE' }),
        invalidatesTags: ['CustomerUsers'],
      }),
      putV1CustomersByCustomerIdUsersAndUserId: build.mutation<
        PutV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        PutV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}`,
          method: 'PUT',
          body: queryArg.updateUser,
        }),
        invalidatesTags: ['CustomerUsers'],
      }),
      getV1CustomersByCustomerIdUsers: build.query<GetV1CustomersByCustomerIdUsersApiResponse, GetV1CustomersByCustomerIdUsersApiArg>({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users` }),
        providesTags: ['CustomerUsers'],
      }),
      postV1CustomersByCustomerIdUsers: build.mutation<
        PostV1CustomersByCustomerIdUsersApiResponse,
        PostV1CustomersByCustomerIdUsersApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users`, method: 'POST', body: queryArg.createUser }),
        invalidatesTags: ['CustomerUsers'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 200 Success */ UserForCustomer;
export type GetV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  /** The customer id. */
  customerId: string;
  /** The userId to retrieve. */
  userId: string;
};
export type DeleteV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 204 No Content */ undefined;
export type DeleteV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  /** The customer id. */
  customerId: string;
  /** The userId to retrieve. */
  userId: string;
};
export type PutV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 200 Success */ UserForCustomer;
export type PutV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  /** The customer id. */
  customerId: string;
  /** The id from the user to update. */
  userId: string;
  /** The userdata to update (replaces current data). */
  updateUser: UpdateUser;
};
export type GetV1CustomersByCustomerIdUsersApiResponse = /** status 200 Success */ UserForCustomer[];
export type GetV1CustomersByCustomerIdUsersApiArg = {
  /** The customer id. */
  customerId: string;
};
export type PostV1CustomersByCustomerIdUsersApiResponse = /** status 201 Created */ UserForCustomer;
export type PostV1CustomersByCustomerIdUsersApiArg = {
  /** The customer id. */
  customerId: string;
  /** The user to create. */
  createUser: CreateUser;
};
export type UserRole = 'unknown' | 'primary' | 'admin' | 'editor' | 'reader';
export type DomainService =
  | 'unknown'
  | 'comms'
  | 'crm'
  | 'ecomm'
  | 'pay'
  | 'subsMgmt'
  | 'webhooks'
  | 'edi'
  | 'aip'
  | 'externalErp'
  | 'shortUrl'
  | 'perfTestClient'
  | 'commercePortal'
  | 'ediPortal'
  | 'paragonRoutingAndScheduling';
export type Subscription = {
  domainService?: DomainService;
  tenantId?: string;
  tenantName?: string | null;
  externalTenantId?: string | null;
  roles?: string[] | null;
};
export type UserForCustomer = {
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  role?: UserRole;
  subscriptions?: Subscription[] | null;
};
export type ApiErrorCode = 'unknown' | 'notFound' | 'validation' | 'forbidden';
export type ApiErrorDetail = {
  code?: ApiErrorCode;
  target?: string | null;
  message?: string | null;
};
export type ApiError = {
  code?: ApiErrorCode;
  message?: string | null;
  domain?: string | null;
  details?: ApiErrorDetail[] | null;
};
export type UpdateUser = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  role?: UserRole;
  subscriptions?: Subscription[] | null;
};
export type CreateUser = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  role?: UserRole;
  subscriptions?: Subscription[] | null;
};
export const {
  useGetV1CustomersByCustomerIdUsersAndUserIdQuery,
  useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation,
  usePutV1CustomersByCustomerIdUsersAndUserIdMutation,
  useGetV1CustomersByCustomerIdUsersQuery,
  usePostV1CustomersByCustomerIdUsersMutation,
} = injectedRtkApi;
