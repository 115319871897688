import { DomainService, UserForCustomer } from '../api/users.api';
import { Role } from './Constants';

export const mapStringToRole = (str: string | null | undefined) => {
  if (!str) {
    return undefined;
  }
  switch (str.toLowerCase()) {
    case 'primary':
      return Role.Primary;
    case 'admin':
      return Role.Admin;
    case 'editor':
      return Role.Editor;
    case 'unknown':
      return Role.Unknown;
    case 'reader':
    default:
      return Role.Reader;
  }
};

export const mapRoleToString = (role: Role) => {
  switch (role) {
    case Role.Primary:
      return 'Primary';
    case Role.Admin: 
      return 'Admin';
    case Role.Editor:
      return 'Editor';
    case Role.Unknown:
      return undefined;
    case Role.Reader:
    default:
      return 'Reader';
  }
}

export const getDomainServiceRole = (user: UserForCustomer | undefined, domainService: DomainService) => {
  if (!user || !user.subscriptions) return undefined;
  const subscription = user.subscriptions.find(sub => {
    return sub.domainService === domainService;
  });
  if (!subscription || !subscription?.roles) return undefined;
  return mapStringToRole(subscription.roles[0]);
};
