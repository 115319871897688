import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer, Subscription, User } from '../../api/userInfo.api';
import { RootState } from '../../app/store';

interface SliceState {
  userInfo?: User;
  subscriptions?: Subscription[] | null;
  isAdmin: boolean;
  customerId?: string | null;
  customerRole?: string;
  customers?: Customer[] | null;
  selectedCustomer?: Customer | null;
}
const initialState: SliceState = {
  userInfo: undefined,
  subscriptions: undefined,
  isAdmin: false,
  customerRole: undefined,
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserInfoAndSubscriptions: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload;
      state.customers = action.payload.customers;
    },
    fetchCustomer: (state, action: PayloadAction<Customer | null | undefined>) => {
      state.selectedCustomer = action.payload;
      state.customerId = action.payload?.id;
      state.customerRole = action.payload?.role;
      state.subscriptions = action.payload?.subscriptions;
      state.isAdmin = action.payload?.role === 'admin' || action.payload?.role === 'primary';
    },
  },
});

export const selectUserInfo = (state: RootState) => state.app.userInfo;
export const selectUserSubscriptions = (state: RootState) => state.app.subscriptions;
export const selectIsAdmin = (state: RootState) => state.app.isAdmin;
export const selectCustomerId = (state: RootState) => state.app.customerId;
export const selectCustomer = (state: RootState) => state.app.selectedCustomer;
export const selectCustomerRole = (state: RootState) => state.app.customerRole;
export const selectCustomers = (state: RootState) => state.app.customers;

export const { 
  setUserInfoAndSubscriptions, 
  fetchCustomer,
} = slice.actions;

export default slice.reducer;
