import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EdiMetrics, ServiceSummary } from "../../api/services.api";
import { Subscription } from "../../api/userInfo.api";
import { RootState } from "../../app/store";

interface SliceState {
  ediMetrics?: EdiMetrics[];
  payAccess: boolean;
  ediAccess: boolean;
  shipAccess: boolean;
  aipAccess: boolean;
}

const initialState: SliceState = {
  payAccess: false,
  ediAccess: false,
  shipAccess: false,
  aipAccess: false
};

export const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setUserAccess: (state, action: PayloadAction<Subscription[]>) => {
      const subs = action.payload;
      subs.forEach(sub => {
        switch (sub.domainService?.toLowerCase()) {
          case 'pay':
            state.payAccess = true;
            break;
          case 'edi':
            state.ediAccess = true;
            break;
          case 'ship':
            state.shipAccess = true;
            break;
          case 'aip':
            state.aipAccess = true;
            break;
          default:
            break;
        };
      });
    },
    setSummaryData: (state, action: PayloadAction<ServiceSummary>) => {
      const keys = Object.keys(action.payload);
      if (keys.length > 0) {
        keys.forEach((key: string) => {
          switch(key) {
            case 'ediMetrics':
              const metrics = action.payload[key];
              if (metrics) {
                state.ediMetrics = metrics;
              }
              break;
            // Leaving room for other products
            default:
              break;
          }
        })
      }
    }
  }
});

export const selectEdiMetrics = (state: RootState): EdiMetrics[] | undefined => state.dashboard.ediMetrics;
export const selectPayAccess = (state: RootState): boolean => state.dashboard.payAccess;
export const selectEdiAccess = (state: RootState): boolean => state.dashboard.ediAccess;
export const selectShipAccess = (state: RootState): boolean => state.dashboard.shipAccess;
export const selectAipAccess = (state: RootState): boolean => state.dashboard.aipAccess;

export const {
  setUserAccess,
  setSummaryData
} = slice.actions;

export default slice.reducer;