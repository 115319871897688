import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import dashboardReducer from '../features/dashboard/DashboardSlice';
import appReducer from '../features/app/AppSlice';

import { emptySplitApi } from '../api/empty.api';


export const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  app: appReducer,
  // RTK Query reducers
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(emptySplitApi.middleware),
  });

export type AppDispatch = AppStore['dispatch'];
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
