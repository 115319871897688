import { useAuth0 } from '@auth0/auth0-react';
import { Container, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { Customer, useGetV1UserinfoQuery } from '../../api/userInfo.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Header from '../../components/Header';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { sec } from '../../util/security';
import ContactAdmin from '../contactAdmin/ContactAdmin';
import Dashboard from '../dashboard/Dashboard';
import UserManagement from '../userManagement/UserManagement';
import { fetchCustomer, selectCustomer, selectCustomers, selectIsAdmin, setUserInfoAndSubscriptions } from './AppSlice';
import { ThemeModeContext } from '../stylingProvider/StylingProvider';
import { getDarkModeForUser, getLanguageForUser } from '../../util/ViewerUtility';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      width: '100%',
      maxWidth: '100%',
      margin: 0,
      padding: 0,
    },
    canvas: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(3, 12),
      },
      [theme.breakpoints.down('xl')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
  }),
);

const AppLegacy: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { i18n, t: translate } = useTranslation();
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);
  const { data: userInfo, isLoading: userLoading, isFetching } = useGetV1UserinfoQuery();
  const isAdmin = useAppSelector(selectIsAdmin);
  const customer = useAppSelector(selectCustomer);
  const customers = useAppSelector(selectCustomers);

  
  const themeMode = useTheme().palette.mode;
  const colorMode = useContext(ThemeModeContext);

  const browserDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  console.log(`[0] AppLegacy.tsx loading`);
  useEffect(() => {
    if (userInfo) {
      dispatch(setUserInfoAndSubscriptions(userInfo));
      if (!customer && userInfo.customers) {
        // Default to the first customer. Later on we can use localStorage to keep track of the user's selected customer
        const firstCustomer = userInfo.customers[0];
        dispatch(fetchCustomer(firstCustomer));
      }
    }
  }, [dispatch, userInfo, customer]);

  useEffect(() => {
    if (userInfo?.id) {
      const userLang = getLanguageForUser(userInfo?.id);
      const darkMode = getDarkModeForUser(userInfo?.id);
      if (userLang) {
        i18n.changeLanguage(userLang);
      }
      if (darkMode) {
        setDarkMode(darkMode);
      }  else {
        // No cookie or localstorage for darkmode (if browser pref is dark use that)
        if (browserDarkMode && themeMode !== 'dark') {
          colorMode.toggleColorMode();
        }
      }
    }
  }, [userInfo?.id])

  const setDarkMode = (mode: string) => {
    if (mode === "true") {
      // Set Dark Mode
      if (themeMode !== "dark") {
        colorMode.toggleColorMode();
      }
    } else {
      if (themeMode !== "light") {
        colorMode.toggleColorMode();
      }
    }
  }

  const switchCustomer = (chosenCustomer: Customer) => {
    if (chosenCustomer.id !== customer?.id) {
      dispatch(fetchCustomer(chosenCustomer));
    }
  }

  // TODO: Get feedback on what to display when loading and when redirecting to Auth0's login screen
  /* const loadingView = (
    <div style={{height:'100%', width:'100%', padding: '50px', color: 'white', backgroundColor: '#5B5B5B', fontSize: '24px', display: 'inline-flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <p style={{ marginBottom: '40px' }}>Loading</p>
      <div style={{display: 'flex', height: '40%', justifyContent: 'center'}}>
          <div className={styles.loadingDots}></div><div id="dotTwo" className={styles.loadingDots}></div><div id="dotThree" className={styles.loadingDots}></div>
      </div>
    </div>
  ); */

  if (isLoading || userLoading || isFetching) {
    return (
      <div
        style={{
          fontSize: '30px',
          margin: 0,
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        Loading...
      </div>
    ); //loadingView;
  }
  if (error) {
    return (
      <div>
        {translate('app.oops')} {error.message}
      </div>
    );
  }

  return (
    <Container className={classes.root} maxWidth={false} disableGutters={true}>
      {isAuthenticated && (
        <Typography variant="h4" component="h1">
          <Header viewerUser={userInfo} isAdmin={isAdmin} customers={customers} selectedCustomer={customer} switchCustomer={switchCustomer} />
        </Typography>
      )}
      <Container role={'main'} className={classes.canvas} maxWidth={false} disableGutters={true}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                component={Dashboard}
                onRedirecting={() => {
                  return (
                    <div
                      style={{
                        fontSize: '30px',
                        margin: 0,
                        textAlign: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {translate('app.redirectingToLogin')}
                    </div>
                  );
                }}
              />
            }
          />
          <Route path="/contact-admin" element={<ContactAdmin />} />
          <Route path="/user-management" element={<UserManagement viewerUser={userInfo} />} />
        </Routes>
      </Container>
    </Container>
  );
};
export default AppLegacy;
