import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['Services'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1ServicesSummary: build.query<GetV1ServicesSummaryApiResponse, GetV1ServicesSummaryApiArg>({
        query: queryArg => ({
          url: `/v1/services/summary`,
          params: { customerId: queryArg.customerId, startDate: queryArg.startDate, endDate: queryArg.endDate },
        }),
        providesTags: ['Services'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1ServicesSummaryApiResponse = /** status 201 Created */ ServiceSummary;
export type GetV1ServicesSummaryApiArg = {
  /** The customer id. */
  customerId: string;
  /** Lower time range. */
  startDate: string;
  /** Upper time range. */
  endDate: string;
};
export type MonetaryValue = {
  currency?: string | null;
  amount?: number;
};
export type EdiMetric = {
  purchaseOrdersProcessed?: number;
  amounts?: MonetaryValue[] | null;
};
export type EdiMetrics = {
  tenantId?: string;
  period?: EdiMetric;
  overall?: EdiMetric;
  openExceptions?: number;
};
export type ServiceSummary = {
  ediMetrics?: EdiMetrics[] | null;
};
export type ApiErrorCode = 'unknown' | 'notFound' | 'validation' | 'forbidden';
export type ApiErrorDetail = {
  code?: ApiErrorCode;
  target?: string | null;
  message?: string | null;
};
export type ApiError = {
  code?: ApiErrorCode;
  message?: string | null;
  domain?: string | null;
  details?: ApiErrorDetail[] | null;
};
export const { useGetV1ServicesSummaryQuery } = injectedRtkApi;
