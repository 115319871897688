import { PaletteMode } from '@mui/material';
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    success?: PaletteColorOptions;
    pageSubHeader?: PaletteColorOptions;
    header?: PaletteColorOptions;
    highEmphasis?: PaletteColorOptions;
    productCardAccessBackground?: PaletteColorOptions;
    productCardNoAccessBackground?: PaletteColorOptions;
    productCardApteanLogoAccess?: PaletteColorOptions;  
    productCardAccessText?: PaletteColorOptions;
    productCardNoAccessText?: PaletteColorOptions;
  }
  interface Palette {
    success: PaletteColor;
    pageSubHeader: PaletteColor;
    header: PaletteColor;
    highEmphasis: PaletteColor;
    productCardAccessBackground: PaletteColor;
    productCardNoAccessBackground: PaletteColor;
    productCardApteanLogoAccess: PaletteColor;
    productCardAccessText: PaletteColor;
    productCardNoAccessText: PaletteColor;
  }
}
  declare module '@mui/material/styles' {
    interface TypographyVariants {
      label1: React.CSSProperties;
      label2: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
      label1?: React.CSSProperties;
      label2?: React.CSSProperties;
    }
  }
  
  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      label1: true;
      label2: true;
    }
  }
  
  const rawTheme = {
    typography: {
      label1: {
        font: 'normal normal 500 12px/14px Roboto',
        letterSpacing: 1.2,
        fontWeight: 500,
        fontSize: 12,
      },
    },
  };

  export const getBaseTheme = (mode: PaletteMode) => ({
    ...rawTheme,
    palette: {
      mode,
      success: {
        main: '#34C759',
      },
      pageSubHeader: {
        main: '#04227B',
      },
      header: {
        ...(mode === 'light')
        ? {
          main: '#00000098',
        } : {
          main: '#ffffffbf'
        }
      },
      highEmphasis: {
        main: '#276EF1',
        contrastText: 'white',
        dark: '#2056BA',
      },
      productCardAccessBackground: {
        main: '#3A3A3A',
      },
      productCardNoAccessBackground: {
        ...(mode === 'light')
        ? {
          main: '#EFEFEF',
        } : {
          main: '#A6A6A6'
        }
      },
      productCardApteanLogoAccess: {
        main: '#FFFC3A'
      },
      productCardAccessText: {
        main: '#FFF'
      },
      productCardNoAccessText: {
        ...(mode === 'light')
        ? {
          main: '#655E58',
        } : {
          main: '#524B46'
        }
      }
    },
  });