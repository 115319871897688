import { alpha, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGrid } from 'devextreme-react';
import { IDataGridOptions } from 'devextreme-react/data-grid';

interface TableTemplateProps {
  gridOptions: IDataGridOptions;
  children?: React.ReactNode | React.ReactNode[];
}

export const TableTemplate: React.FC<TableTemplateProps> = props => {
  const { gridOptions, children } = props;

  return (
    <DataGrid keyExpr="id" {...gridOptions}>
      {children}
    </DataGrid>
  );
};
