import { useContext, useEffect, useState } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Divider, FormControlLabel, Grid, MenuItem, MenuList, Stack, Switch, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectCustomerRole } from '../features/app/AppSlice';
import { User } from '../api/userInfo.api';
import { canManageUsers, getLanguageForUser, setDarkModeForUser, setLanguageForUser } from '../util/ViewerUtility';
import LanguageSelect from '../components/LanguageSelect';
import { ThemeModeContext } from '../features/stylingProvider/StylingProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'start',
    },
    userInfo: {
      padding: theme.spacing(3),
    },
    menuItem: {
      height: 55,
      textDecoration: 'none',
      color: '#276EF1',
    },
    menuItemIcon: {
      marginRight: theme.spacing(2),
      color: '#276EF1',
    },
    themeLabel: {
      '& span': {
        paddingRight: theme.spacing(2),
      },
    },
  }),
);

interface SettingsMenuProps {
  afterClick: () => void;
  handleLogout: () => void;
  viewerUser?: User | undefined;
  isAdmin: boolean;
  logoutButtonText?: string | undefined | null;
}

const SettingsMenu: React.FC<SettingsMenuProps> = props => {
  const { afterClick, handleLogout, viewerUser, isAdmin, logoutButtonText } = props;
  const classes = useStyles();
  const { t: translate, i18n } = useTranslation();
  const navigate = useNavigate();
  const customerRole = useAppSelector(selectCustomerRole);
  const [selectedLang, setSelectedLang] = useState('');

  const canAddUser = canManageUsers(isAdmin, viewerUser);
  const colorMode = useContext(ThemeModeContext);
  const currTheme = useTheme().palette.mode;

  const addUser = () => {
    navigate('/user-management');
    afterClick();
  };

  const changeLanguage = (lang: string) => {
    if (lang) {
      i18n.changeLanguage(lang);
      setLanguageForUser(viewerUser?.id, lang);
      afterClick();
    }
  };

  useEffect(() => {
    //get and set starting language
    if (viewerUser?.id) {
      const userLang = getLanguageForUser(viewerUser?.id);
      if (userLang) {
        setSelectedLang(userLang);
      } else {
        setSelectedLang('en-US');
      }
    }
  }, [viewerUser?.id]);

  const switchThemeHandler = () => {
    const val = currTheme !== 'dark';
    setDarkModeForUser(viewerUser?.id, val.toString());
    colorMode.toggleColorMode();
  };

  const getThemeOption = () => {
    const checked = currTheme === 'dark';
    return (
      <FormControlLabel
        className={classes.themeLabel}
        value="start"
        control={
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Brightness7Icon />
            <Switch checked={checked} onChange={switchThemeHandler} />
            <Brightness4Icon />
          </Stack>
        }
        label={translate('settingsMenu.theme')}
        labelPlacement="start"
      />
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid className={classes.userInfo} item container xs={12}>
        <Grid item xs={9}>
          <Typography variant="h6">
            {viewerUser?.firstName} {viewerUser?.lastName}
          </Typography>
          <Typography variant="subtitle1">{viewerUser?.email}</Typography>
          <Typography variant="subtitle1">
            {customerRole ? customerRole.charAt(0).toUpperCase() + customerRole.slice(1) : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MenuList data-cy="settings-menu" autoFocusItem>
          {canAddUser && (
            <MenuItem className={classes.menuItem} onClick={addUser} data-cy="add-user">
              <PersonAddIcon className={classes.menuItemIcon} />
              {translate('settingsMenu.addUser')}
            </MenuItem>
          )}
          <LanguageSelect languageChanged={changeLanguage} menuItemClassName={classes.menuItem} language={selectedLang} />
          {getThemeOption()}
          <MenuItem className={classes.menuItem} onClick={handleLogout} data-cy="sign-out">
            <ExitToAppIcon className={classes.menuItemIcon} />
            {logoutButtonText ?? translate('settingsMenu.logout')}
          </MenuItem>
        </MenuList>
      </Grid>
    </Grid>
  );
};

export default SettingsMenu;
