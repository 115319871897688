import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Container, Paper, Theme, Typography, } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { FC, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactAdminForm from "../../components/ContactAdminForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    rootBackground: {
      width: '100%',
      height: 'fit-content',
      minHeight: '100vh',
      backgroundImage: 'url(https://stapteanidb2cdev001.blob.core.windows.net/customui/login-background.svg)',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      backgroundColor: '#5b5b5b',
      position: 'absolute',
      top: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(500)]: {
        height: '100%'
      }
    },
    paper: {
      margin: 'auto',
      backgroundColor: '#3a3a3a',
      color: 'white',
      width: '400px',
      padding: theme.spacing(5),
      [theme.breakpoints.down(600)]: {
        height: '100%'
      },
      [theme.breakpoints.down(500)]: {
        width: '100%',
        height: '100%'
      },
    },
    apteanLogo: {
      height: '75px',
      maxWidth: '100%',
      objectFit: 'contain',
      margin: '0 auto',
    },
    successBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: theme.spacing(2, 0, 3),
    },
    successHeader: {
      fontSize: '24px',
      
      display: 'inline-block'
    },
    successIcon: {
      marginRight: theme.spacing(0.5),
      fontSize: '50px',
      color: '#34C759',
      height: '50px',
      display: 'inline-block'
    },
    successInfo: {
      fontSize: '14px',
      fontStretch: '100%',
      margin: theme.spacing(2, 0, 3)
    },
    button: {
      backgroundColor: '#fffb65',
      color: '#3a3a3a',
      textTransform: 'none',
      fontSize: '16px',
      padding: theme.spacing(0.5, 2),
      minHeight: '52px',
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: '#e5e15b'
      }
    },
  }),
);

const ContactAdmin: FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const [formStep, setFormStep] = useState(true);
  const [completedStep, setCompletedStep] = useState(false);

  const handleSendEmailClick = () => {
    // TODO: Do the backend hookups to send the email
    setFormStep(false);
    setCompletedStep(true);
  }
  return (
    <Container classes={{root: classes.rootBackground}} disableGutters maxWidth={false}>
      <Paper className={classes.paper} elevation={0}>
        <Box display={'flex'} justifyContent={'center'}>
          <img src={'https://stapteanidb2cdev001.blob.core.windows.net/customui/ApteanLogo-YellowIcon-WhiteText.png'} alt="Aptean Logo" className={classes.apteanLogo} />
        </Box>
        <ContactAdminForm showForm={formStep} onButtonClick={handleSendEmailClick}/>
        <Box display={completedStep ? "block" : "none"}>
          <Box className={classes.successBox}>
            <CheckCircleIcon className={classes.successIcon} />
            <Typography className={classes.successHeader} variant="h1">{translate('contactAdmin.sentHeader')}</Typography>
          </Box>
          <Typography className={classes.successInfo}>{translate('contactAdmin.sentInfo')}</Typography>
          <Button 
            fullWidth
            variant='contained'
            className={classes.button}
             onClick={() => loginWithRedirect()}
          >
            {translate('contactAdmin.returnButton')}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ContactAdmin;