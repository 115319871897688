import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['UserInfo'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1Userinfo: build.query<GetV1UserinfoApiResponse, GetV1UserinfoApiArg>({
        query: () => ({ url: `/v1/userinfo` }),
        providesTags: ['UserInfo'],
      }),
      getV1UserinfoByExternalId: build.query<GetV1UserinfoByExternalIdApiResponse, GetV1UserinfoByExternalIdApiArg>({
        query: queryArg => ({ url: `/v1/userinfo/${queryArg.externalId}` }),
        providesTags: ['UserInfo'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1UserinfoApiResponse = /** status 200 Success */ User;
export type GetV1UserinfoApiArg = void;
export type GetV1UserinfoByExternalIdApiResponse = /** status 200 Success */ User;
export type GetV1UserinfoByExternalIdApiArg = {
  /** External user id provided. */
  externalId: string;
};
export type UserRole = 'unknown' | 'primary' | 'admin' | 'editor' | 'reader';
export type DomainService =
  | 'unknown'
  | 'comms'
  | 'crm'
  | 'ecomm'
  | 'pay'
  | 'subsMgmt'
  | 'webhooks'
  | 'edi'
  | 'aip'
  | 'externalErp'
  | 'shortUrl'
  | 'perfTestClient'
  | 'commercePortal'
  | 'ediPortal'
  | 'paragonRoutingAndScheduling';
export type Subscription = {
  domainService?: DomainService;
  tenantId?: string;
  tenantName?: string | null;
  externalTenantId?: string | null;
  roles?: string[] | null;
};
export type Customer = {
  id?: string;
  name?: string | null;
  role?: UserRole;
  coId?: string | null;
  subscriptions?: Subscription[] | null;
};
export type User = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  externalId?: string | null;
  customers?: Customer[] | null;
};
export type ApiErrorCode = 'unknown' | 'notFound' | 'validation' | 'forbidden';
export type ApiErrorDetail = {
  code?: ApiErrorCode;
  target?: string | null;
  message?: string | null;
};
export type ApiError = {
  code?: ApiErrorCode;
  message?: string | null;
  domain?: string | null;
  details?: ApiErrorDetail[] | null;
};
export const { useGetV1UserinfoQuery, useGetV1UserinfoByExternalIdQuery } = injectedRtkApi;
