import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { StorageKeyAuthProvider, AuthProviderOIDC, StorageKeyRedirect, StorageKeyFrontChannelLogoutRequestedTime, StorageKeyAppEntryTime, StorageKeyAuthSession } from "./Constants";

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

export const sec = {
    getAccessTokenSilently: () => getAccessTokenSilently,
    setAccessTokenSilently: (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) => 
        (getAccessTokenSilently = func),
};

export const isApteanSSOProvider = () => {
    const authProvider = sessionStorage.getItem(StorageKeyAuthProvider);
    if (authProvider && authProvider === AuthProviderOIDC) {
      return true;
    }
    return false;
};

export const clearSSOSessionStorage = () => {
  sessionStorage.removeItem(StorageKeyAuthSession);
  sessionStorage.removeItem(StorageKeyRedirect);
  sessionStorage.removeItem(StorageKeyAppEntryTime);
  localStorage.removeItem(StorageKeyFrontChannelLogoutRequestedTime);
};

export const getSSOAccessToken = () => {
  const userData = sessionStorage.getItem(StorageKeyAuthSession);
  const parsedUserData = JSON.parse(userData || '{}');
  return parsedUserData.access_token;
}