// taken from EDI and modified to remove id from confirm
import { Dialog } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationPrompt from '../ConfirmationPrompt';

interface DeleteDialogProps {
  isOpen: boolean;
  heading: string;
  message: string;
  onConfirm: () => void;
  onReject: () => void;
  errorMessage?: string | undefined;
  deleteStatus: QueryStatus;
}

const DeleteDialog: React.FC<DeleteDialogProps> = props => {
  const { isOpen, heading = 'Delete', message, onConfirm, onReject, errorMessage, deleteStatus } = props;
  const [isDeleteBusy, setIsDeleteBusy] = useState(false); 
  const { t: translate } = useTranslation();

  useEffect(() => {
    setIsDeleteBusy(deleteStatus === QueryStatus.pending);
  }, [deleteStatus]);

  const handleDeleteAreaConfirm = () => {
    setIsDeleteBusy(true);
    onConfirm();
  };
  const handleDeleteAreaReject = () => {
    setIsDeleteBusy(false);
    onReject();
  };

  return (
    <Dialog open={isOpen}>
      <ConfirmationPrompt
        heading={heading}
        message={message}
        confirmButtonText={translate('userManagement.delete')}
        rejectButtonText={translate('userManagement.cancel')}
        confirmButtonColor={'error'}
        rejectButtonColor={'primary'}
        handleConfirm={handleDeleteAreaConfirm}
        handleReject={handleDeleteAreaReject}
        isBusy={isDeleteBusy}
        errorMessage={errorMessage}
      />
    </Dialog>
  );
};

export default DeleteDialog;
