import { Container, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MonetaryValue, useGetV1ServicesSummaryQuery } from '../../api/services.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ProductTile from '../../components/ProductTile';
import { selectCustomer, selectCustomerId, selectUserSubscriptions } from '../app/AppSlice';
import {
  selectEdiAccess,
  selectEdiMetrics,
  selectPayAccess,
  selectShipAccess,
  setSummaryData,
  setUserAccess,
} from './DashboardSlice';
import { IP_IAM_INDICATOR, QUERY_PARAM_IP, REDIRECT_CONSUMER_INDICATOR, StorageKeyRedirect, IAM_EDI_PORTAL_BASE_URL, EDI_PORTAL_BASE_URL } from '../../util/Constants';
import { isApteanSSOProvider } from '../../util/security';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(4, 0),
    },
    productItem: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

interface DashboardProps {
  totalCustomerCount?: number;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const { totalCustomerCount = 0 } = props;
  const classes = useStyles();
  const subscriptions = useAppSelector(selectUserSubscriptions);
  //const payAccessible = useAppSelector(selectPayAccess);
  const ediAccessible = useAppSelector(selectEdiAccess);
  //const shipAccessible = useAppSelector(selectShipAccess);
  const customerId = useAppSelector(selectCustomerId);
  const ediMetrics = useAppSelector(selectEdiMetrics);
  const customer = useAppSelector(selectCustomer);
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const now = DateTime.now();

  const { data: services } = useGetV1ServicesSummaryQuery(
    {
      customerId: customerId || '',
      startDate: now.startOf('quarter').toUTC().toISO(),
      endDate: now.endOf('quarter').toUTC().toISO(),
    },
    {
      skip: !customerId,
    },
  );

  useEffect(() => {
    if (isApteanSSOProvider() && totalCustomerCount === 1 && ediMetrics && ediMetrics.length === 1){
      let isRedirectFromConsumer = sessionStorage.getItem(StorageKeyRedirect) === REDIRECT_CONSUMER_INDICATOR;
      // If NOT redirected from consumer portal and
      // there is only 1 customer and 1 tenant, navigate directly to that tenant edi portal
      if (!isRedirectFromConsumer && ediMetrics[0].tenantId) {
        toApteanEdi(ediMetrics[0].tenantId);
      }
    }
  }, [ediMetrics, totalCustomerCount]);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      dispatch(setUserAccess(subscriptions));
    }
  }, [subscriptions]);

  useEffect(() => {
    if (services) {
      dispatch(setSummaryData(services));
    }
  }, [services]);

  const findEdiTenantName = (tenantId: string | undefined) => {
    if (!tenantId || !customer || !customer.subscriptions) return '';
    const subscription = customer.subscriptions.find(sub => {
      return sub.tenantId === tenantId;
    });
    return subscription?.tenantName || '';
  }

  // TODO: Get all of the following via API later
  // ApteanPay
  // const payAmount = { value: 19700000, type: 'currency' };
  // const payExceptions = 0;
  // const payMoreInfoLink = `${process.env.REACT_APP_APTEAN_PAY_INFO_URL}`;
  // // TODO: Do this function properly
  // const toApteanPay = () => {
  //   window.location.assign(`${process.env.REACT_APP_APTEAN_PAY_BASE_URL}`);
  // };
  // const toPayExceptions = () => console.log('TODO: redirect to Aptean Pay Exceptions :(');

  const getEDIPortalHref = () => {
    let href = isApteanSSOProvider() ? IAM_EDI_PORTAL_BASE_URL : EDI_PORTAL_BASE_URL;
    return href;
  };

  const ediMoreInfoLink = `${process.env.REACT_APP_APTEAN_EDI_INFO_URL}`;
  const toApteanEdi = (tenantId: string) => {
    const url = new URL(getEDIPortalHref() || '');
    url.searchParams.append('tenantId', tenantId);
    if (customerId) {
      url.searchParams.append('customerId', customerId);
    }
    if (isApteanSSOProvider()) {
      url.searchParams.append(QUERY_PARAM_IP, IP_IAM_INDICATOR);
    }
    // remove redirect key if present
    if (sessionStorage.getItem(StorageKeyRedirect)) {
      sessionStorage.removeItem(StorageKeyRedirect);
    }
    window.location.assign(url.href);
  };
  const toEdiExceptions = (tenantId: string) => {
    const url = new URL(`${getEDIPortalHref()}exceptions` || '');
    url.searchParams.append('tenantId', tenantId);
    if (customerId) {
      url.searchParams.append('customerId', customerId);
    }
    if (isApteanSSOProvider()) {
      url.searchParams.append(QUERY_PARAM_IP, IP_IAM_INDICATOR);
    }
    window.location.assign(url.href);
  };

  // Aptean Ship
  // var shipDetail; // TODO: DON'T KNOW WHAT THIS WILL BE YET
  // const shipExceptions = 0;
  // const shipMoreInfoLink = `${process.env.REACT_APP_APTEAN_SHIP_INFO_URL}`;
  // const toApteanShip = () => console.log('TODO: redirect to Aptean Ship :)');
  // const toShipExceptions = () => console.log('Aptean Pay Exceptions :(');

  const formatNumbers = (amount: number) => {
    return new Intl.NumberFormat('en', {
      notation: amount > 999.99 ? 'compact' : 'standard',
      style: 'decimal',
    }).format(amount);
  };

  const currencyFormatter = (amount: number, currency: string) => {
    if (amount < 999999.99) {
      return new Intl.NumberFormat('en', {
        notation: amount > 999.99 ? 'compact' : 'standard',
        style: 'currency',
        currency: currency !== '' ? currency : 'USD',
        currencyDisplay: 'symbol',
      }).format(amount);
    } else {
      const inParts = new Intl.NumberFormat('en', {
        notation: 'compact',
        style: 'currency',
        currency: currency !== '' ? currency : 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 1,
      }).formatToParts(amount);
      let full = '';
      inParts.forEach((part: { type: string; value: string }) => {
        if (part.type !== 'fraction') {
          full += part.value;
        } else if (part.value.length > 1 && part.value.charAt(1) === '0') {
          full += part.value.charAt(0);
        } else if (part.value.length > 1) {
          full += Number(`0.${part.value}`).toFixed(1).replace('0.', '');
        } else {
          full += part.value;
        }
      });
      return full;
    }
  };

  const detailFormatter = (detail: number | { amount: number; currency: string }, type: string) => {
    if (type === 'currency') {
      let num = typeof detail === 'number' ? detail : detail.amount;
      let cur = typeof detail === 'number' ? '' : detail.currency;
      return currencyFormatter(num, cur);
    } else if (type === 'number' && typeof detail === 'number') {
      return formatNumbers(detail);
    } else {
      return detail;
    }
  };
  
  const ediTiles = ediMetrics?.map(ediInfo => {
    let ediOrders = 0;
    let ediAmount = { amount: 0, currency: 'usd' } as MonetaryValue | undefined;
    let ediExceptions = 0;
    if (ediInfo) {
      ediExceptions = !!ediInfo?.openExceptions ? ediInfo?.openExceptions : 0;
      if (ediAccessible) {
        ediOrders = ediInfo.period?.purchaseOrdersProcessed || 0;
        ediAmount = ediInfo.period?.amounts?.find(amt => {
          return amt.currency?.toLowerCase() === 'usd';
        });
      } else {
        ediOrders = ediInfo.overall?.purchaseOrdersProcessed || 0;
        ediAmount = ediInfo.overall?.amounts?.find(amt => {
          return amt.currency?.toLowerCase() === 'usd';
        });
      }
    }
    return (
      <Grid item md={6} className={classes.productItem} key={ediInfo.tenantId}>
        <ProductTile
          onRedirectClick={() => {
            if (ediInfo.tenantId) {
              toApteanEdi(ediInfo.tenantId);
            }
          }}
          onExceptionsClick={() => {
            if (ediInfo.tenantId) {
              toEdiExceptions(ediInfo.tenantId);
            }
          }}
          productName={'Aptean EDI'}
          userHasAccess={{
            hasAccess: ediAccessible,
            showSubtitle: !!ediInfo,
          }}
          exceptions={ediExceptions}
          moreInfoLink={ediMoreInfoLink}
          subTitle={{
            emphasized: `${findEdiTenantName(ediInfo.tenantId)} - ${detailFormatter(ediOrders, 'number')} ${translate('dashboard.apteanEdiDetail')}`,
            regular: `${ediAmount ? detailFormatter(ediAmount as { amount: number; currency: string }, 'currency') : ''}  ${
              ediAccessible ? translate('dashboard.apteanEdiSubDetail') : translate('dashboard.apteanEdiGlobalSubDetail')
            }`,
          }}
        />
      </Grid>
    );
  });

  return (
    <Container maxWidth={false} className={classes.rootContainer} disableGutters>
      {/* Product Tiles */}
      <Grid
        container
        spacing={{ xl: 6, lg: 5, md: 4, sm: 3, xs: 3 }}
        flexDirection="row"
        justifyContent={'space-between'}
        alignItems="flex-start"
      >
        {ediTiles}
        {/* Hiding these as not active and likely never will be with now having Aptean One 
        <Grid item md={6} className={classes.productItem}>
          <ProductTile
            onRedirectClick={toApteanPay}
            onExceptionsClick={toPayExceptions}
            productName={'Aptean Pay'}
            userHasAccess={{
              hasAccess: payAccessible,
              showSubtitle: false,
            }}
            exceptions={payExceptions}
            moreInfoLink={payMoreInfoLink}
            subTitle={{
              emphasized: `${detailFormatter(payAmount.value, payAmount.type)}`,
              regular: `${translate('dashboard.apteanPayDetail')}`,
            }}
          />
        </Grid>
        <Grid item md={6} className={classes.productItem}>
          <ProductTile
            onRedirectClick={toApteanShip}
            onExceptionsClick={toShipExceptions}
            productName={'Aptean Ship'}
            userHasAccess={{
              hasAccess: shipAccessible,
              showSubtitle: false,
            }}
            exceptions={shipExceptions}
            moreInfoLink={shipMoreInfoLink}
            subTitle={shipDetail}
          />
        </Grid>
        */}
      </Grid>
    </Container>
  );
};

export default Dashboard;
