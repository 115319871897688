import { Role } from '../../util/Constants';
import { UserForCustomer } from '../../api/users.api';
import { Subscription } from '../../api/userInfo.api';

export interface UserInfoRow {
  name: string;
  email: string;
  id: string;
  commerceRole: Role | undefined;
  ediRole: Role | undefined;
}

export interface SubscriptionInfoRow {
  tenantId: string | undefined;
  tenantName: string | null | undefined;
  id: string;
}

export const makeDataRowsFromUsers = (users: UserForCustomer[]) => {
  let ret = [] as UserInfoRow[];
  if (users) {
    let i = 0;
    for (i; i < users.length; ++i) {
      let commerceRole = users[i].role;
      const ediSubscription = users[i].subscriptions?.find(sub => {
        return sub.domainService === 'edi';
      });
      let ediRole = ediSubscription && ediSubscription.roles ? ediSubscription.roles[0] : undefined;
      ret.push({
        name: users[i].firstName + ' ' + users[i].lastName,
        email: users[i].email || '',
        commerceRole: commerceRole as Role,
        ediRole: ediRole as Role,
        id: users[i].id || '',
      });
    }
  }

  return ret;
};

export const makeDataRowsFromSubscriptions = (subs: Subscription[]) => {
  let ret = [] as SubscriptionInfoRow[];
  if (subs) {
    let i = 0;
    for (i; i < subs.length; ++i) {
      ret.push({
        tenantId: subs[i].tenantId,
        tenantName: subs[i].tenantName,
        id: subs[i].tenantId as string,
      });
    }
  }
  return ret;
}
