import { Container, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { Customer, useGetV1UserinfoQuery } from '../../api/userInfo.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Header from '../../components/Header';
import ContactAdmin from '../contactAdmin/ContactAdmin';
import Dashboard from '../dashboard/Dashboard';
import UserManagement from '../userManagement/UserManagement';
import {
  fetchCustomer,
  selectCustomer,
  selectCustomers,
  selectIsAdmin,
  setUserInfoAndSubscriptions,
} from './AppSlice';
import { ThemeModeContext } from '../stylingProvider/StylingProvider';
import { getDarkModeForUser, getLanguageForUser } from '../../util/ViewerUtility';
import { useAuth } from 'react-oidc-context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      width: '100%',
      maxWidth: '100%',
      margin: 0,
      padding: 0,
    },
    canvas: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(3, 12),
      },
      [theme.breakpoints.down('xl')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
  }),
);

const App: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { i18n, t: translate } = useTranslation();

  const [isTokenSet, setIsTokenSet] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  console.log(`[0] App.tsx calling useAuth from react-oidc-context`);
  const auth = useAuth();

  // don't call this query until we have a token to pass in the headers
  const {
    data: userInfo,
    isLoading: userLoading,
    isFetching,
  } = useGetV1UserinfoQuery(undefined, {
    skip: !isTokenSet,
  });

  const isAdmin = useAppSelector(selectIsAdmin);
  const customer = useAppSelector(selectCustomer);
  const customers = useAppSelector(selectCustomers);

  const themeMode = useTheme().palette.mode;
  const colorMode = useContext(ThemeModeContext);

  const browserDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (!auth.isLoading && auth.isAuthenticated) {
      console.log('[1] App.tsx setting token');
      const accessToken = auth.user?.access_token;
      if (accessToken) {
        console.log('[1] App.tsx setting token');
        setIsTokenSet(true);
      }
      const potentialEmail = auth.user?.profile?.email ? auth.user?.profile?.email : null;
      if (potentialEmail) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }
  }, [auth.isLoading, auth.isAuthenticated]);

  useEffect(() => {
    if (userInfo) {
      dispatch(setUserInfoAndSubscriptions(userInfo));
      if (!customer && userInfo.customers) {
        // Default to the first customer. Later on we can use localStorage to keep track of the user's selected customer
        const firstCustomer = userInfo.customers[0];
        dispatch(fetchCustomer(firstCustomer));
      }
    }
  }, [dispatch, userInfo, customer]);

  useEffect(() => {
    if (userInfo?.id) {
      const userLang = getLanguageForUser(userInfo?.id);
      const darkMode = getDarkModeForUser(userInfo?.id);
      if (userLang) {
        i18n.changeLanguage(userLang);
      }
      if (darkMode) {
        setDarkMode(darkMode);
      } else {
        // No cookie or localstorage for darkmode (if browser pref is dark use that)
        if (browserDarkMode && themeMode !== 'dark') {
          colorMode.toggleColorMode();
        }
      }
    }
  }, [userInfo?.id]);

  const setDarkMode = (mode: string) => {
    if (mode === 'true') {
      // Set Dark Mode
      if (themeMode !== 'dark') {
        colorMode.toggleColorMode();
      }
    } else {
      if (themeMode !== 'light') {
        colorMode.toggleColorMode();
      }
    }
  };

  const switchCustomer = (chosenCustomer: Customer) => {
    if (chosenCustomer.id !== customer?.id) {
      dispatch(fetchCustomer(chosenCustomer));
    }
  };

  if (auth.isLoading || userLoading || isFetching) {
    return (
      <div
        style={{
          fontSize: '30px',
          margin: 0,
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        Loading...
      </div>
    ); //loadingView;
  }
  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return (
      <div
        style={{
          fontSize: '30px',
          margin: 0,
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        Redirecting...
      </div>
    );
  }

  return (
    <Container className={classes.root} maxWidth={false} disableGutters={true}>
      {isAuthenticated && (
        <Typography variant="h4" component="h1">
          <Header
            viewerUser={userInfo}
            isAdmin={isAdmin}
            customers={customers}
            selectedCustomer={customer}
            switchCustomer={switchCustomer}
          />
        </Typography>
      )}
      <Container role={'main'} className={classes.canvas} maxWidth={false} disableGutters={true}>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Dashboard totalCustomerCount={customers?.length} /> : <div>Not Authenticated</div>}
          />
          <Route path="/contact-admin" element={<ContactAdmin />} />
          <Route path="/user-management" element={<UserManagement viewerUser={userInfo} />} />
        </Routes>
      </Container>
    </Container>
  );
};
export default App;
