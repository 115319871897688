import { DateTime } from 'luxon';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { StorageKeyFrontChannelLogoutRequestedTime } from '../util/Constants';
import { useEffect } from 'react';

const FrontChannelLogout: React.FC = () => {
  let logoutTime = DateTime.now().toUTC().toISO();
  localStorage.setItem(StorageKeyFrontChannelLogoutRequestedTime, logoutTime);
  // need to dispatch the event to trigger the logout
  window.dispatchEvent(new StorageEvent("storage", { key: StorageKeyFrontChannelLogoutRequestedTime, newValue: logoutTime }));

  useEffect(() => {
    window.close();
  }, []);

  return (
    <Box
      sx={{ backgroundColor: 'black', minWidth: '100vw', minHeight: '100vh', padding: 4 }}
      justifyContent="center"
      display="flex"
      alignItems="center"
    >
      <Box>
        <Card sx={{ backgroundColor: '#34363a', color: 'white' }}>
          <CardContent>
            <Typography>Logging out...</Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default FrontChannelLogout;
