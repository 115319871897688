import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography, styled } from '@mui/material';

const MainContainer = styled(Box)(props => ({
  backgroundColor: '#fafafa', 
  background: 'url("/aptean-bg.jpg") no-repeat center center', 
  backgroundSize: 'cover', 
  minWidth: '100vw', 
  minHeight: '100vh', 
  padding: 0
}));

const StyledCard = styled(Card)(props => ({
  margin: '0 auto',
  marginTop: '96px',
  boxShadow: '0 0.5rem 1rem 0 rgba(3,3,3,0.64),0 0 0.375rem 0 rgba(3,3,3,0.32)',
  padding: '20px 40px 30px 40px',
  maxWidth: '500px',
  minWidth: '420px',
  color: 'white',
  backgroundColor: '#34363a',
  borderRadius: '5px',
}));

const StyledButton = styled(Button)(props => ({
  backgroundColor: '#fffc3b !important', 
  color: '#212121',
  '&:hover': {
    fontWeight: 700,
  },
  '&:active': {
    backgroundColor: '#2196f3',
  },
}));

const PostLogout: React.FC = () => {
  const headerProps = {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Arial, Helvetica, sans-serif',
    marginTop: '0.5em'
  };

  return (
    <MainContainer
      justifyContent="center"
      display="flex"
      alignItems="flex-start"
    >
      <Box>
        <StyledCard>
          <CardHeader title="You have been successfully signed out." titleTypographyProps={headerProps} />
          <CardContent>
            <Typography>Thank you for using Aptean Customer Portal</Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <StyledButton variant="contained" href="/">
              Sign In
            </StyledButton>
          </CardActions>
        </StyledCard>
      </Box>
    </MainContainer>
  );
};

export default PostLogout;
