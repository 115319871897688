export const APTEAN_IAM_CLIENT_ID = `${process.env.REACT_APP_IAM_CLIENT_ID}`;
export const APTEAN_IAM_AUTHORITY = `${process.env.REACT_APP_IAM_AUTH_URL}`;
export const APTEAN_IAM_REALM = `${process.env.REACT_APP_IAM_REALM}`;
export const APTEAN_IAM_READ_SCOPE = `${process.env.REACT_APP_IAM_SCOPE}`;
export const APTEAN_IAM_WRITE_SCOPE = `${process.env.REACT_APP_IAM_SCOPE}`;
export const EDI_PORTAL_BASE_URL = `${process.env.REACT_APP_EDI_PORTAL_BASE_URL}`;
export const IAM_EDI_PORTAL_BASE_URL = `${process.env.REACT_APP_IAM_EDI_PORTAL_BASE_URL}`;

export enum Role {
  Admin = 'admin',
  Editor = 'editor',
  Reader = 'reader',
  Unknown = 'unknown',
  Primary = 'primary'
}

export const QUERY_PARAM_REDIRECT = 'redirect';
export const QUERY_PARAM_IP = 'ip';
export const IP_IAM_INDICATOR = 'iam';
export const REDIRECT_CONSUMER_INDICATOR = 'consumer';
export const StorageKeyAuthProvider = 'auth-provider';
export const AuthProviderAuth0 = 'auth0';
export const AuthProviderOIDC = 'oidc';
// react-oidc-context stores the auth session in sessionStorage using this key.
export const StorageKeyAuthSession = `oidc.user:${APTEAN_IAM_AUTHORITY}/realms/${APTEAN_IAM_REALM}:${APTEAN_IAM_CLIENT_ID}`;
export const StorageKeyRedirect = 'redirect-from';
export const StorageKeyAppEntryTime = 'app-entry-time';
export const StorageKeyFrontChannelLogoutRequestedTime = 'front-channel-logout-requested-time';