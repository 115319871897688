//taken from EDI
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    styled,
    Typography,
} from '@mui/material';
import * as React from 'react';

interface ConfirmationPromptProps {
  heading: string;
  message: string;
  confirmButtonText: string;
  rejectButtonText: string;
  isBusy?: boolean;
  errorMessage?: string;
  confirmButtonColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  rejectButtonColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  handleConfirm: () => void;
  handleReject: () => void;
}

const ConfirmationPrompt: React.FC<ConfirmationPromptProps> = (props: ConfirmationPromptProps) => {
  const ConfirmationPromptStyled = styled(Paper)(props => ({
    '& .MuiDialog-paper': { width: '80%', maxHeight: 435 },
  }));
  const StyledDialogActions = styled(DialogActions)(props => ({
    margin: '0 8px 8px 0',
  }));
  const StyledDialogContentText = styled(DialogContentText)(props => ({
    whiteSpace: 'pre-line', //to allow for line breaks in the string message
  }));
  const StyledTypography = styled(Typography)(props => ({
    whiteSpace: 'pre-line', //to allow for line breaks in the string message
    fontSize: '14px',
  }));

  let isDisabled = (props.errorMessage && props.errorMessage.length > 0) || props.isBusy === true;

  return (
    <ConfirmationPromptStyled>
      <DialogTitle>{props.heading}</DialogTitle>
      {props.errorMessage && (
        <Alert severity="error">
          <AlertTitle>Unable to {props.heading}</AlertTitle>
          <StyledTypography>{props.errorMessage}</StyledTypography>
        </Alert>
      )}
      <DialogContent>
        <StyledDialogContentText>{props.message}</StyledDialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <CircularProgress
          aria-label={'progress spinner'}
          size={20}
          sx={{
            display: props.isBusy ? 'block' : 'none',
            margin: '0 10px 0 0',
          }}
        />
        <Button
          disabled={props.isBusy}
          autoFocus
          color={props.rejectButtonColor}
          onClick={props.handleReject}
          sx={{
            margin: '0 5px 0 0',
          }}
        >
          {props.rejectButtonText}
        </Button>
        <Button variant="contained" disabled={isDisabled} color={props.confirmButtonColor} onClick={props.handleConfirm}>
          {props.confirmButtonText}
        </Button>
      </StyledDialogActions>
    </ConfirmationPromptStyled>
  );
};

export default ConfirmationPrompt;
