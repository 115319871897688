import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer, User } from '../api/userInfo.api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ApteanLogo } from './ApteanLogo';
import SettingsMenuApteanSSO from './SettingsMenuApteanSSO';
import SettingsMenuAuth0 from './SettingsMenuAuth0';
import { isApteanSSOProvider } from '../util/security';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      boxShadow: '0px 3px 6px #00000012',
      backgroundColor: '#3A3A3A',
    },
    // TODO: Toolbar padding does not perfectly match the mocks
    extraToolbarPadding: {
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(0, 12),
      },
      [theme.breakpoints.down('xl')]: {
        padding: theme.spacing(0, 5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
    titleLink: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '4px 4px 4px 0px',
      '&:focus': {
        outline: '2px solid #fffc3b',
      },
    },
    apteanLogo: {
      height: '30px',
      marginRight: theme.spacing(1),
    },
    apteanTitle: {
      fontSize: '25px',
      fontFamily: 'Suisse Intl Cond Bold',
      color: 'white',
      marginTop: '5px',
      lineHeight: '40px',
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: 'normal',
      },
    },
    mdIndexGrow: {
      flexGrow: 1,
      zIndex: 1,
      position: 'relative',
    },
    settingsBox: {
      width: 400,
    },
    userButton: {
      '&:focus': {
        outline: '2px solid #fffc3b',
      },
    },
  }),
);

interface HeaderProps {
  viewerUser?: User | undefined;
  isAdmin: boolean;
  customers?: Customer[] | null;
  selectedCustomer?: Customer | null;
  switchCustomer: (chosenCustomer: Customer) => void;
}

const Header: FC<HeaderProps> = props => {
  const { viewerUser, isAdmin, customers, selectedCustomer, switchCustomer } = props;
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const handleClick = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [customersMenuAnchorEl, setCustomersMenuAnchorEl] = useState<Element | null>();
  const openCustomersMenu = (event: { currentTarget: Element }) => {
    setCustomersMenuAnchorEl(event.currentTarget);
  };
  const closeCustomersMenu = () => {
    setCustomersMenuAnchorEl(null);
  };
  const handleCustomerSelect = (customer: Customer) => {
    switchCustomer(customer);
    closeCustomersMenu();
  };

  let initials = <PersonIcon />;
  if (viewerUser) {
    let userInitials = viewerUser.firstName ? viewerUser.firstName[0] : '';
    userInitials += viewerUser.lastName ? viewerUser.lastName[0] : '';
    initials = <span>{userInitials}</span>;
  }

  // TODO: Correctly pass in ViewerUser info to SettingsMenu
  return (
    <div className={matches ? classes.grow : classes.mdIndexGrow} id="primary-app-bar">
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.extraToolbarPadding}>
          <Link href="/" underline="none" className={classes.titleLink}>
            <ApteanLogo className={classes.apteanLogo} style={{ fill: 'white' }} />
            <Typography variant="h6" className={classes.apteanTitle}>
              {`${translate('header.logo')}`}
            </Typography>
          </Link>
          <Typography variant="h6" className={classes.apteanTitle} sx={{ marginLeft: '4px' }}>
            {'-'}
          </Typography>
          {customers && customers.length === 1 && (
            <Typography className={classes.apteanTitle} sx={{ marginLeft: '8px' }}>
              {selectedCustomer?.name}
            </Typography>
          )}
          {customers && customers.length > 1 && (
            <Button sx={{ textTransform: 'none', color: 'white' }} endIcon={<ArrowDropDownIcon />} onClick={openCustomersMenu}>
              <Typography className={classes.apteanTitle}>{selectedCustomer?.name}</Typography>
            </Button>
          )}
          <Menu anchorEl={customersMenuAnchorEl} open={Boolean(customersMenuAnchorEl)} onClose={closeCustomersMenu}>
            {customers?.map(customer => {
              return (
                <MenuItem
                  onClick={() => {
                    handleCustomerSelect(customer);
                  }}
                  key={customer.id}
                >
                  {customer.name}
                </MenuItem>
              );
            })}
          </Menu>
          <div className={classes.grow} />
          <IconButton className={classes.userButton} onClick={handleClick} title="User Settings" data-cy="user-settings">
            <Avatar data-cy="user-avatar" style={{ backgroundColor: '#eaeaea', color: '#3A3A3A' }}>
              {initials}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ disablePadding: true }}
            marginThreshold={0}
            variant="menu"
          >
            <Box className={classes.settingsBox}>
            {isApteanSSOProvider() ? (
                <SettingsMenuApteanSSO afterClick={handleClose} viewerUser={viewerUser} isAdmin={isAdmin} />
              ) : (
                <SettingsMenuAuth0 afterClick={handleClose} viewerUser={viewerUser} isAdmin={isAdmin} />
              )
            }
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
