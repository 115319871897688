import { Box, Button, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formBox: {
      paddingTop: theme.spacing(2),
    },
    formInfoText: {
      fontSize: '14px',
      fontStretch: '100%',
      marginBottom: theme.spacing(3),
    },
    textInputs: {
      color: 'white',
      backgroundColor: '#4c5759',
      borderColor: '#4c5759',
      width: '100%',
      borderRadius: '3px',
      marginBottom: theme.spacing(1.5),
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#fffb65',
        },
      },
      '& .MuiInputBase-input': {
        color: 'white',
      },
    },
    textInputLabels: {
      color: '#c9c9c9',
      backgroundColor: '#4c5759',
      '&.Mui-focused': {
        color: '#fffb65',
        padding: '0px 8px',
        left: -4,
        fontSize: '18px',
      },
      '&.MuiInputLabel-shrink': {
        fontSize: '18px',
        padding: '0px 8px',
        left: -4,
      },
    },
    sendButton: {
      backgroundColor: '#fffb65',
      color: '#3a3a3a',
      textTransform: 'none',
      fontSize: '16px',
      padding: theme.spacing(0.5, 2),
      minHeight: '52px',
      marginTop: theme.spacing(2),
      '&$disabled': {
        backgroundColor: '#9E9E9E',
      },
      '&:hover': {
        backgroundColor: '#e5e15b',
      },
    },
    disabled: {},
  }),
);
interface ContactAdminFormProps {
  showForm: boolean;
  onButtonClick: () => void;
}

const ContactAdminForm: React.FC<ContactAdminFormProps> = props => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { showForm, onButtonClick } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const checkButton = () => {
    let validInputs = name.trim() !== '' && email.trim() !== '' && subject.trim() !== '' && message.trim() !== '';
    if (
      validInputs &&
      email.match(
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      )
    ) {
      setButtonDisabled(false);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    checkButton();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    checkButton();
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
    checkButton();
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
    checkButton();
  };

  return (
    <Box className={classes.formBox} display={showForm ? 'block' : 'none'}>
      <Typography className={classes.formInfoText}>{translate('contactAdmin.description')}</Typography>
      <TextField
        variant="outlined"
        id="name"
        label={translate('contactAdmin.nameLabel')}
        className={classes.textInputs}
        InputLabelProps={{ className: classes.textInputLabels }}
        value={name}
        onChange={handleNameChange}
      />
      <TextField
        variant="outlined"
        id="email"
        label={translate('contactAdmin.emailLabel')}
        className={classes.textInputs}
        InputLabelProps={{ className: classes.textInputLabels }}
        value={email}
        onChange={handleEmailChange}
      />
      <TextField
        variant="outlined"
        id="subject"
        label={translate('contactAdmin.subjectLabel')}
        className={classes.textInputs}
        InputLabelProps={{ className: classes.textInputLabels }}
        value={subject}
        onChange={handleSubjectChange}
      />
      <TextField
        variant="outlined"
        id="message-body"
        label={translate('contactAdmin.messageLabel')}
        multiline
        minRows={5}
        className={classes.textInputs}
        InputLabelProps={{ className: classes.textInputLabels }}
        value={message}
        onChange={handleMessageChange}
      />
      <Button
        disabled={buttonDisabled}
        fullWidth
        variant="contained"
        classes={{
          root: classes.sendButton,
          disabled: classes.disabled,
        }}
        onClick={onButtonClick}
      >
        {translate('contactAdmin.buttonLabel')}
      </Button>
    </Box>
  );
};

export default ContactAdminForm;
